import React, { useEffect, useRef, useState } from "react";
import AdminSideBar from "./AdminSideBar";
import { AdminNavigation } from "../../Admin/AdminNavigation";
import UseOutsideClick from "../../Admin/UseOutsideClick";
import close from "../../../images/Sidebar/close.svg";
import { NavLink } from "react-router-dom";
import { MdOutlineCorporateFare } from "react-icons/md";
import { RiContactsBookUploadFill, RiMentalHealthFill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { MdManageAccounts } from "react-icons/md";
import { GiHypodermicTest,GiHealthNormal } from "react-icons/gi";
import userimg from "../../../images/Sidebar/adminuser.png";


const AdminLayout = (props) => {
  const [sidemenu, setsidemenu] = useState(false);
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (sidemenu) {
      setsidemenu(false);
    }
  });
  
  useEffect(() => {
    window.scrollTo({top : 0 , behavior : 'smooth'});
  },[])
  return (
    <>
      <div className="relative">
        <div className="sticky top-0 z-30 shadow-sm">
          <AdminNavigation setsidemenu={setsidemenu} />
        </div>
        <div className="lg:flex">
          <div className="fixed bottom-0 z-10 top-[72px] min-h-[91.5vh] overflow-y-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            <AdminSideBar />
          </div>

          {/* mobile side bar */}
          <div
            className={`${
              sidemenu &&
              `${"backcolor absolute z-[1002] top-0 bottom-0 right-0 left-0"}`
            } lg:hidden`}
          >
            <div
              className={`${
                sidemenu
                  ? "translate-x-0 h-full max-w-[335px] fixed bottom-0 px-[16px] pt-[25px] pb-[15px]"
                  : "-translate-x-full "
              } transform  ease-in-out transition duration-500  bg-[#FFFFFF] flex flex-col justify-between lg:hidden`}
              ref={ref}
            >
              {sidemenu && (
                <div className="min-w-[250px] flex-col justify-between flex">
                  <div>
                    <img
                      src={close}
                      className=" ml-auto"
                      onClick={() => setsidemenu(false)}
                      alt="new"
                    ></img>
                  </div>
                  <div>
                    <ul className="px-3 py-5 flex flex-col gap-5 w-[273px]">
                      <li>
                        <NavLink
                          to="/admin/admin_user/corporate"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <MdOutlineCorporateFare />
                          Corporate
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/hr"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <span className="text-xl">
                            <MdManageAccounts />
                          </span>
                          HR
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/uploadeemployee"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <RiContactsBookUploadFill />
                          Create Employee
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/employee"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <HiUserGroup />
                          Employee List
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/export"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <HiUserGroup />
                          Export
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/doctor"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                         <GiHealthNormal />
                          Doctor
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/healthtips"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <RiMentalHealthFill />
                          Health Tips
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to="/admin/admin_user/labtest"
                          className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
                        >
                          <GiHypodermicTest />
                          Book A Lab Test
                        </NavLink>
                      </li>
                      <li>
              <NavLink
                to="/admin/admin_user/panel"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <img src={userimg} alt="wellness" className="w-[20px]" />
                Vibhuti
              </NavLink>
            </li>  
                      {/* <li>
              <NavLink
                to="/admin/admin_user/panel"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <img src={userimg} alt="wellness" className="w-[20px]" />
                Vibhuti Upload
              </NavLink>
            </li>   */}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="w-full  md:pl-[40px] lg:pl-[250px] min-h-[91.5vh] md:pr-[16px] bg-[#FFFCE1]">
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLayout;

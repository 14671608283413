import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
//import tostify 
import { ToastContainer } from "react-toastify";
//import action file
import {
  fileLoader,
  resetForm,
  selectFile,
  uploadEmployee,
} from "../../Redux/Action/UploadEmployeeAction";
import { GetActiveCorporateOptionlist } from "../../Redux/Action/CorporateAction";
//import icon
import { RiAddLine } from "react-icons/ri";
import ClearFilter from "../../images/clear filter icon.png";
//import loader
import Loader from "../../component/Admin/Loader";
import { EmployeeCreateModal } from "../../component/Modal/AdminModal/CreateModal";


const UploadeEmployee = () => {
  
  const dispatch = useDispatch();
  // get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const onDrop = (acceptedFiles) => {
    dispatch(setSelectFile(acceptedFiles[0]));
  };

  const [Error, setError] = useState("");

  //for upload file
  const handleSubmit = async (e) => {
    const selectedCorporateValue = selectedCorporate
      ? selectedCorporate.value
      : "";
    e.preventDefault();
    if (!selectedCorporate) {
      setError("Please select a corporate from the dropdown list.");
      return;
    } else {
      // Clear the error message if corporate is selected
      setError("");
    }
    if (!selectedFile) {
      dispatch(selectFile("please select a file"));
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("corporateID", selectedCorporateValue);
      dispatch(uploadEmployee(formData, token));
      dispatch(fileLoader());
      dispatch(selectedCorporate(selectedCorporateValue));
      dispatch(resetForm());
      setSelectFile(null);
      setSelectedCorporate("");
    } catch (error) {
      // console.log("error");
    }
  };

  const newdata = useSelector((state) => state?.employee?.NewEmployee);
  // console.log(newdata);

  useEffect(() => {
    if (newdata.code === 200) {
      setSelectFile("");
      setSelectedCorporate("");
      setcorporateID("");
      setUploadEmployeemodal(false);
    }
  }, [newdata]);

  const [selectedFile, setSelectFile] = useState(null);

  // for searchable-dropdwon
  const [corporateID, setcorporateID] = useState("");
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [optionList, setOptionList] = useState([]);

  const Corporatename = useSelector(
    (state) => state?.corporateData?.ActiveCorporateOptionList?.data
  );

  useEffect(() => {
    // const con =
    //   Corporatename &&
    //   Corporatename?.map((c) => ({
    //     value: c.corporateID,
    //     label: c.corporateName,
    //   }));
    const filteredData = Corporatename && Corporatename?.filter(item => !["Vibhuti", "Imagica" , "Miraj cinema"]?.includes(item.corporateName))?.map((c) => ({
          value: c.corporateID,
          label: c.corporateName,
        }));
    setOptionList(filteredData);
  }, [Corporatename]);

  useEffect(() => {
    dispatch(GetActiveCorporateOptionlist());
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/xlsx": [".xls",".xlsx"],
    },
  });

  function handleSelect(selectedOption) {
    setSelectedCorporate(selectedOption);
    setcorporateID(selectedOption);
    setError("");
  }

  // for searchable-dropdwon css
  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontFamily: "Roboto",
        color: "#1A1A1A",
      };
    },
    option: (defaultStyles) => ({
      ...defaultStyles,
      fontFamily: "Roboto",
      color: "#1A1A1A",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#808080",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#808080",
    }),
    control: (base, state) => ({
      ...base,
      height: "40px",
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      boxShadow: "none !important",
    }),
    option: (base, state) => ({
      ...base,
      cursor: "pointer",
      color: "#1A1A1A",
      backgroundColor: state.isSelected ? "#FCDD00" : "white",
      ":hover": {
        backgroundColor: "#FFFCE1",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
      overflow: "Y",
    }),
  };

  // //for doenload sample file
  const [uploadEmployeemodal, setUploadEmployeemodal] = useState(false);

  const loader = useSelector((state) => state?.employee?.fileloader);

  const [showdelete, setshowdelete] = useState(false);

  useEffect(() => {
      if (selectedCorporate) {
        setshowdelete(true);
      } else {
        setshowdelete(false);
      }
    },
    [selectedCorporate]
  );

  //for delete corporate filter

  const handlecorporatedelete = () => {
    setSelectedCorporate("");
  };

  return (
    <>
      <AdminLayout>
        {/* for loader  */}
        {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-50 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
        {/* for create employee modal  */}
        {uploadEmployeemodal && (<EmployeeCreateModal {...{setUploadEmployeemodal,optionList,setError}}/>)}
      
        <div className="flex flex-col h-full  p-5 mb-10">
          {/* --------------------------------------------Search Bar---------------------------------------------------------- */}
          <div className="bg-white p-4 rounded-lg  w-full mb-5">
            <div className=" md:flex justify-between items-center">
              {/* filter div */}
              <div className=" flex items-center gap-2">
                <div>
                  <div className="flex flex-col gap-2">
                    <div className=" flex gap-3">
                      <Select
                        className="w-[180px]"
                        // options={optionList}
                        options={optionList?.map(option => ({
                          ...option,
                          label: option.label.length > 17 ? option.label.slice(0, 17) + '...' : option.label
                        }))}
                        placeholder="Select Corporate"
                        value={selectedCorporate}
                        onChange={handleSelect}
                        isSearchable={true}
                        styles={customStyles}
                        // isMulti
                      />
                      {showdelete && (
                        <button
                          className="  bg-[#FCDD00] py-1 px-2 xl:px-4  rounded-[4px] del-tooltip"
                          onClick={handlecorporatedelete}
                        >
                          <img src={ClearFilter} alt="new"></img>
                          <span className="del-tooltiptext">Clear Filter</span>
                        </button>
                      )}
                      
                    </div>
                    <div>
                        {Error && <p className="text-red-500 mt-1">{Error}</p>}
                      </div>
                  </div>
                </div>
              </div>
              {/* create employee div  */}
             <div className="mt-3 md:mt-0">
                <button
                  onClick={() => setUploadEmployeemodal(true)}
                  className="bg-[#FCDD00] text-[#1A1A1A] px-3 py-[6px] rounded-lg font-Poppins text-lg flex items-center gap-1 cursor-pointer  hover:scale-105"
                >
                  <span className="text-2xl">
                    <RiAddLine />
                  </span>
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* --------------------------------------------Content Table------------------------------------------------------- */}
          <div className="w-full h-full  flex flex-col gap-3">
            <div className=" text-xl text-[#1A1A1A] font-Roboto">
            Upload your filled employee list file here ( .xls, .xlsx):<span className="text-[#fc0000] ml-1"> *</span>
            </div>
            {/*---------------- For Select File-------------- */}
            <div className=" cursor-pointer">
              {" "}
              <div
                {...getRootProps({
                  className:
                    "flex flex-col items-center justify-center border-2 border-[#808080] border-dashed p-3 py-10  md:p-16 rounded-lg bg-white",
                })}
              >
                <input {...getInputProps()} />
                <div>
                  <svg
                    className="w-8 h-8 mb-4 text-[#808080]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                </div>
                <p className="text-sm text-[#808080] font-Roboto text-center">
                Click to upload the employee list file OR drag & drop the file here.
                </p>
                {selectedFile && (
                  <p className=" text-[#808080] font-Roboto mt-3 text-center">
                    Selected File :{selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            {/* -----------------------Button--------------------- */}
            <div className="md:flex gap-5 justify-center items-center mt-5 mx-auto">
              <div>
                <a
                  href="https://welcomcure-corporate-image.b-cdn.net/corporate/SampleFile.xlsx"
                  className="border border-[#FCDD00] text-[#1A1A1A] bg-white w-[178px]  py-2 rounded-lg font-Roboto flex justify-center"
                >
                  Download Sample File
                </a>
              </div>
              <div className="mt-3 md:mt-0 flex justify-center">
                {selectedFile && (
                  <button
                    onClick={handleSubmit}
                    className="bg-[#FCDD00] text-[#1A1A1A] px-10 py-2 rounded-lg font-Roboto w-[170px] outline-none"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
      <ToastContainer />
    </>
  );
};

export default UploadeEmployee;
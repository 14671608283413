import React from 'react';
import { Navigate } from 'react-router-dom';

function DoctorRoute  ({ children })  {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    const userData = JSON.parse(authToken); 

    const isAuth = token || undefined;
    const userName = userData?.data?.role;
    const role = userName || undefined;
    return isAuth && role === "Doctor" ? children : <Navigate to="/login" />;
}

export default DoctorRoute
import React, { Fragment, useEffect, useState } from "react";
import AdminLayout from "../../component/layout/AdminLayout/AdminLayout";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
//import todtify
import { ToastContainer } from "react-toastify";
//import icon
import { RiAddLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import { BsEyeFill } from "react-icons/bs";
import LimitArrow from "../../images/LimitArrow.svg"
//import action file 
import {
  DeleteImage,
  GetImageList,
} from "../../Redux/Action/HealthTipsAction";
//import sweetAlert
import Swal from "sweetalert2";
import Loader from "../../component/Admin/Loader";
import { HealthTipsCreateModal } from "../../component/Modal/AdminModal/CreateModal";
import { Menu, Transition } from "@headlessui/react";

const HealthTips = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const [uploadImageModal, setUploadImageModal] = useState(false);


  useEffect(() => {
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(GetImageList({ ...object }));
  }, []);

  const [page, setpage] = useState("");
  const [Itemperpage, setItemperpage] = useState("");
  const [selectpage, setselectpage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const itemsPerPageOptions = [
    { value: 12, label: 12, id: 1 },
    { value: 24, label: 24, id: 2 },
    { value: 50, label: 50, id: 3 },
  ];

  const ImageList = useSelector((state) => state?.HealthTips?.Imglist.data);

  const pageCount = Math.ceil(Number(ImageList?.count) / Number(page));
  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [ImageList]);

  useEffect(() => {
    setpage(itemsPerPageOptions[0]?.value);
  }, []);

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, []);
  //  for selected page data
  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      setselectpage(0)
      history({
        pathname: "/admin/admin_user/healthtips",
        search:
          "?" + new URLSearchParams({ ...object, limit: item.value, page:1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/healthtips",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetImageList({ ...object, limit: item.value, page:1 }));
  };
  // for pagination
  const handleFirstPage = () => {
    setselectpage(0);
    setCurrentPage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/healthtips",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/healthtips",
        search: `?page=1`,
      });
    }
    dispatch(GetImageList({ ...object, page: 1 }));
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    setCurrentPage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/admin_user/healthtips",
        search: "?" + new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/healthtips",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(GetImageList({ ...object, page: lastPage + 1 }));
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    setCurrentPage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/admin_user/healthtips",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/admin_user/healthtips",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetImageList({ ...object, page: selected.selected + 1 }));
  };

  // to close pop on successful
  const newData = useSelector((state) => state?.HealthTips?.selectedimage);

  useEffect(() => {
    if (newData.code === 200) {
      setUploadImageModal(false);
    }
  }, [newData]);

  // for delte image
  const handleDelete = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Image!",
      icon: "warning",
      iconColor: "#FCDD00",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#FCDD00",
      cancelButtonColor: "#FFFCE1",
      customClass: {
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        dispatch(DeleteImage(index, { ...object }));
      }
    });
  };

  const loader = useSelector((state) => state?.HealthTips?.imageloader);

  // for single image view
  const [viewImageModal, setViewImageModal] = useState(false);
  const [viewedImageUrl, setViewedImageUrl] = useState(null);

  const openViewImageModal = (imageUrl) => {
    setViewedImageUrl(imageUrl);
    setViewImageModal(true);
  };

  const closeViewImageModal = () => {
    setViewedImageUrl(null);
    setViewImageModal(false);
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <AdminLayout>
      {loader && (
        <div>
          <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
            <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Loader />
            </div>
          </div>
        </div>
      )}
      {/*----------------------------- Image Upload Model ----------------------------------*/}
      {uploadImageModal && (<HealthTipsCreateModal {...{uploadImageModal,setUploadImageModal}}/>)}
      {/*----------------------------- Image Upload Model Over ----------------------------------*/}
      {/* -------------------------------------viwe single image model --------------------------------- */}
      {viewImageModal && (
        <div
          className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-50 bg-opacity-50"
          onClick={closeViewImageModal}
        >
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="w-full">
              <img
                src={viewedImageUrl}
                alt="Viewed Image"
                className="rounded-xl shadow-sm shadow-yellow-50 min-w-[300px] sm:min-w-[400px] w-[400px] h-[400px] xl:w-full xl:h-full"
              />
            </div>
          </div>
        </div>
      )}
      {/* -------------------------------------viwe single image model over--------------------------------- */}
      <div className="flex flex-col  h-full  p-5">
        {/* --------------------------------------------Search Bar---------------------------------------------------------- */}
        <div className="bg-white p-4 rounded-lg  w-full mb-5">
          <div className=" flex justify-end items-center">
            <button
              onClick={() => setUploadImageModal(true)}
              className="bg-[#FCDD00] text-[#1A1A1A] px-3 py-[6px] rounded-lg font-Poppins text-lg flex items-center gap-1 cursor-pointer  hover:scale-105"
            >
              <span className="text-2xl">
                <RiAddLine />
              </span>
              Add
            </button>
          </div>
        </div>
        {/* --------------------------------------------Content Table------------------------------------------------------- */}
        <div className="w-full  h-[640px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-5 xl:gap-7 p-3">
            {ImageList?.image?.map((imageItem, index) => (
              <div key={index} className=" rounded-2xl overflow-hidden  shadow-lg shadow-gray-700 hover:scale-105 relative content">
                <div className="content-overlay"></div>
                <img
                  src={`${ImageList?.storageurl}${imageItem.image}`}
                  alt={`Image ${index}`}
                  className="w-full h-full rounded-2xl content-image"
                />
                <div className="content-details fadeIn-bottom">
                  <button
                    className="content-title border-2 border-white rounded-full p-2"
                    onClick={() =>
                      openViewImageModal(
                        `${ImageList?.storageurl}${imageItem.image}`
                      )
                    }
                  >
                    <BsEyeFill />
                  </button>
                  <button
                    className="content-title ml-5 border-2 border-white rounded-full p-2 hover:border-red-700 hover:text-red-700"
                    onClick={() => handleDelete(imageItem?._id)}
                  >
                    {" "}
                    <AiOutlineDelete />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* --------------------------------------------Footer---------------------------------------------------------- */}
        <div className="footer-content mt-10">
          <div className=" md:flex justify-between items-center">
            <div className="flex gap-2 items-center">
              <p className="capitalize">View</p>
              <div>
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                        <p className="text-[#47505F] font-medium font-[poppins]">
                          {page}
                        </p>
                        <img src={LimitArrow} alt="LimitArrow" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                        {itemsPerPageOptions?.map((e) => (
                          <div key={e?.id}>
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-[#FFFCE1] text-[#1A1A1A]" : "",
                                    "block px-2 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => onselectedpage(e)}
                                >
                                  {e?.value}
                                </p>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
              </div>
            </div>
            {/* --------------------------------------------Footer Pagination---------------------------------------------------------- */}

            <div className="flex items-center mt-3 md:mt-0">
                <button
                className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[5.5px]"
                onClick={handleFirstPage}
              >
                &lt;&lt;
                </button>
                <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Itemperpage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
                forcePage={selectpage}
                />
                <button
                className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[5.5px]"
                onClick={handleLastPage}
              >
                &gt;&gt;
                </button>
              </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </AdminLayout>
  );
};

export default HealthTips;
import React from "react";
import DoctorLayout from "../../component/layout/DoctorLayout/DoctorLayout";

const Appointment = () => {
  return (
    <DoctorLayout>
      {/* <div>Appointment</div> */}
      <div>
        
      </div>
    </DoctorLayout>
  );
};

export default Appointment;

const actionTypes = {
  REPORT_DATA: "REPORT_DATA",
};

const initialState = {
  reportdata: [],
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_DATA:
      return {
        ...state,
        reportdata: action.reportdata,
      };

    default:
      return state;
  }
};

export default ReportReducer;

import React from "react";
import { Navigate } from "react-router";

function CorporateRoute({ children }) {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    const userData = JSON.parse(authToken);

    const isAuth = token || undefined;
    const userName = userData?.data?.role;
    const role = userName || undefined;
    return isAuth && role === "Corporate" ? children : <Navigate to="/login" />;
}
 export default CorporateRoute;
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//action type file
const actionTypes = {
  ADD_DOCTOR: "ADD_DOCTOR",
  DOCTOR_LIST: "DOCTOR_LIST",
  DOCTOR_DETAIL: "DOCTOR_DETAI",
  EDIT_DOCTOR_DETAIL: "EDIT_DOCTOR_DETAIL",
  DOC_TIME_SLOT: "DOC_TIME_SLOT",
  LOADER: "LOADER",
  DOC_EMP_LIST:"DOC_EMP_LIST",
  DOC_EMP_REPORT_DETAIL:" DOC_EMP_REPORT_DETAIL",
  DOC_EMP_FAMILY_DETAIL: " DOC_EMP_FAMILY_DETAIL",
  RESET_DATA: "RESET_DATA",
};

export const DoctorLoader = () => {
  return {
    type: actionTypes.LOADER,
    doctorloader: true,
  };
};


export const resetData = () => {
  return {
    type: actionTypes.RESET_DATA,
  };
};


//   for create doc
export const CreateDoctor = (Data) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/doctor`, Data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_DOCTOR,
          addDoctor: response.data,
          doctorloader: false,
        });
        dispatch(GetDoctorList())
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) => {
        if (err) {
          dispatch({
            type: actionTypes.LOADER,
            doctorloader: false,
          });
          toast.error(err?.response?.data?.error, {
            theme: "colored",
            autoClose: 5000,
          });
        }
      });
  };
};

// for doctor list
export const GetDoctorList = (query) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/doctor`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOCTOR_LIST,
          doctorList: response.data,
        });
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.response?.data?.error, {
            theme: "colored",
            autoClose: 5000,
          });
        }
      });
  };
};

// Doctor Detail

export const GetDoctorDetail = (_id) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/admin/doctor/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOCTOR_DETAIL,
          doctorDetail: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// delete doctor

export const DeleteDoctor = (id, query) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/admin/doctor/${id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetDoctorList(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// update doctor status
export const UpdateDoctor = (_id, status, query) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
  };

  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/admin/doctor/${_id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetDoctorList(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// for edit doctor detail
export const EditDoctor = (_id,name,qualification,email,contactno,address,file) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  var data = new FormData();
  data.append("name", name);
  data.append("qualification", qualification);
  data.append("email", email);
  data.append("contactno", contactno);
  data.append("address", address);
  data.append("profilepic", file);

  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/admin/profile/${_id}`,data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch(GetDoctorList());
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.EDIT_DOCTOR_DETAIL,
          editdoctordetail: response.data,
          doctorloader: false,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOADER,
          doctorloader: false,
        });
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        });
      });
  };
};

// doctor login modal 

export const GetTimeSlot = () => {
  return async (dispatch) => {
    const authToken = localStorage.getItem("Admin");
    const token = JSON.parse(authToken)?.data?.token;
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/timeslot`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOC_TIME_SLOT,
          doctimeslote: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

//doctor employee list
export const GetDocEmpList = (query) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/doctor/employee`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOC_EMP_LIST,
          docemplist: response.data,
        });
      })
      .catch((err) => {
        if (err) {
          toast.error(err?.response?.data?.error, {
            theme: "colored",
            autoClose: 5000,
          });
        }
      });
  };
};

//emp report detail 
export const GetEmpReportDetail = (_id) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/report/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOC_EMP_REPORT_DETAIL,
          docempreport: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// emp family detail
export const GetEmpFamilyDetail = (_id) => {

  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/employee/member/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.DOC_EMP_FAMILY_DETAIL,
          docempfamily: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};



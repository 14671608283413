import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RiContactsBookUploadFill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { MdManageAccounts } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import arrow from "../../../images/Sidebar/arrow.svg";
import { useEffect } from "react";

const CorporateSidebar = () => {
  const location = useLocation();
  const [openmenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const isEmployeePage =
      location.pathname.includes("/corporate/uploademployee") ||
      location.pathname.includes("/corporate/employee");
    setOpenMenu(isEmployeePage);
  }, [location.pathname]);

  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);
  return (
    <div className="lg:flex flex-no-wrap min-h-[91.8vh] hidden">
      <div className="min-w-[230px] bg-white  flex-col justify-between flex">
        <div>
          <ul className="px-3 py-5 flex flex-col gap-2 min-w-[231px]">
            <li>
              <NavLink
                to="/corporate/hr"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <MdManageAccounts /> HR
              </NavLink>
            </li>
            {/* <li>
          <NavLink
            to="/corporate/uploademployee"
            className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
          >
            <RiContactsBookUploadFill /> Create Employee
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/corporate/employee"
            className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
          >
            <HiUserGroup /> Employee List
          </NavLink>
        </li> */}

            <li onClick={() => setOpenMenu(!openmenu)}>
              <div
                className={`flex items-center justify-between px-[12px]  py-3 group rounded-[10px] cursor-pointer`}
              >
                <div className=" flex items-center gap-3">
                  <span>
                    <HiUserGroup />
                  </span>
                  <span className=" font-Roboto text-lg text-[#1A1A1A]">
                    Employee
                  </span>
                </div>
                <img
                  src={arrow}
                  //  className={`${openmenu ? `${'rotate-180'}` : `${'-rotate-360'}`}  `}
                ></img>
              </div>
            </li>

            {openmenu && (
              <div className="pl-[22px]">
                {newcorporate?.data?.name !== 'Vibhuti' && <NavLink
                  to="/corporate/uploademployee"
                  className="flex items-center font-Roboto text-base text-[#1A1A1A] py-3"
                >
                  {/* <RiContactsBookUploadFill /> */}
                  Create Employee
                </NavLink>}
                <NavLink
                  to="/corporate/employee"
                  className="flex items-center  font-Roboto text-base text-[#1A1A1A] py-3"
                >
                  {/* <HiUserGroup /> */}
                  Employee List
                </NavLink>
              </div>
            )}

            {/*-----------Employee menu over ------------------*/}
            <li>
              <NavLink
                to="/corporate/report"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <TbReport /> Report
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CorporateSidebar;

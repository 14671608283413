export const actionTypes = {
    SELECT_CORPORATE: "SELECT_CORPORATE",
    SELECT_FILE: "SELECT_FILE",
    UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "UPLOAD_FAILURE",
    RESET_FORM: "RESET_FORM",
    LOADER: "LOADER"
  };

const initialState = {
    selectedFile : null,
    error: '',
    successMessage: '',
    NewEmployee: [],
    fileloader: false,
};


const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADER:
            return {
                ...state,
                fileloader: action.fileloader,
               
            }
        case actionTypes.SELECT_FILE:
            return {
                ...state,
                selectedFile: action.paylod,
               
            }
        case actionTypes.UPLOAD_SUCCESS: 
        return {
            ...state,
            NewEmployee: action.NewEmployee,
            fileloader: action.fileloader,
         
        }  
        case actionTypes.UPLOAD_FAILURE:
            return {
                ...state,
                selectedFile: action.payload,
                fileloader: action.fileloader,
                
            } 
        case actionTypes.RESET_FORM:
            return {
                ...initialState,
            } 
        default:
            return state;    
    }
}

export default employeeReducer;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
//import react icon
import { MdClose } from "react-icons/md";
//import react select
import Select from "react-select";
// import corpo hr action file
import { CreateCorporateHr, HRLoader } from "../../../Redux/Action/HrAction";
//import corpo emp action file 
import {
  CreateCorporateEmployee,
  fileLoader,
} from "../../../Redux/Action/UploadEmployeeAction";

//*********************************************************** corporate new hr modal
export const CorpoHrModal = ({ setHrmodel }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactno] = useState("");
  const [optionList, setOptionList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");

  useEffect(() => {
    if (user) {
      setSelectedCorporate(newcorporate?.data?.corporateID);
    }
  }, [user]);

  const Corporatename = useSelector(
    (state) => state?.corporateData?.CorporateOptionlist?.data
  );

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
      }));
    setOptionList(con);
  }, [Corporatename]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      name,
      email,
      contactno,
      corporateID: newcorporate?.data?.corporateID,
      HrId: newcorporate?.data?.HrId,
    };

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(
      CreateCorporateHr(formData, newcorporate?.data?.corporateID, {
        ...object,
      })
    );
    dispatch(HRLoader());
  };

  // for searchable-dropdwon css
  const customStyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      padding: 5,
      border: "0px solid black",
      fontSize: 14,

      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),

    //remove dropdown
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Hr</div>
            <button
              onClick={() => {
                setHrmodel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div>
                  <Select
                    options={optionList}
                    placeholder="Select Corporate"
                    value={optionList?.filter(
                      (option) => option.value === selectedCorporate
                    )}
                    isDisabled
                    isSearchable={true}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  HR Name<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="text"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Hr Name"
                  id="name"
                  name="name"
                  value={name}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                  }
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const trimmedValue = enteredValue.trim();
                    if (trimmedValue !== "" || enteredValue === "") {
                      setName(enteredValue);
                    }
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Email Id<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="email"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Hr Email Id"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Contact No<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="tel"
                  className="py-[10px] xl:py-3 px-4 text-sm rounded-lg bg-[#FFFCE1] outline-[#FCDD00]"
                  placeholder="Enter Hr Contact No"
                  id="contact"
                  name="contact"
                  value={contactno}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                  }
                  onChange={(e) => setContactno(e.target.value)}
                  minLength={0}
                  // maxLength={10}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

//***************************************************** corporate new single employee modal
export const CorpoEmpModal = ({ setCreateemployeemodel }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [corporate, setCorporate] = useState("");
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [optionList, setOptionList] = useState([]);

  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);

  useEffect(() => {
    if (user) {
      setSelectedCorporate(newcorporate?.data?.corporateID);
    }
  }, [user]);

  const Corporatename = useSelector(
    (state) => state?.corporateData?.CorporateOptionlist?.data
  );

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
      }));
    setOptionList(con);
  }, [Corporatename]);

  const handleSave = (e) => {
    e.preventDefault();
    const formData = {
      name,
      email,
      contact,
      corporateID: newcorporate?.data?.corporateID,
      HrId: newcorporate?.data?.HrId,
    };
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(fileLoader());
    dispatch(CreateCorporateEmployee(formData))
      .then((response) => {
        if (response && response.data && response.data.success) {
          setContact("");
          setEmail("");
          setName("");
          setCorporate("");
          setCreateemployeemodel(false);
        }
      })
      .catch((error) => {
        // console.error("API request failed:", error);
      });
  };

  // for searchable-dropdwon css
  const customstyles = {
    //to remove seprator
    indicatorSeparator: () => ({}),

    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080", // Red border when focused, blue when not focused
      "&:hover": {
        borderColor: "#FCDD00", // Red border when hovering
      },
      padding: 5,
      border: "0px solid black",
      fontSize: 14,

      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };
  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-50 bg-opacity-50">
        <div className="w-[340px] sm:w-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Add Employee</div>
            <button
              onClick={() => {
                setCreateemployeemodel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSave}>
            <div className="p-3 xl:p-5 flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <div>
                  <Select
                    options={optionList}
                    placeholder="Select Corporate"
                    value={optionList?.filter(
                      (option) => option.value === selectedCorporate
                    )}
                    isSearchable={true}
                    isDisabled
                    styles={customstyles}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Employee Name<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="text"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Name"
                  id="name"
                  name="name"
                  value={name}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9\s]/g,
                      ""
                    ))
                  }
                  onChange={(e) => {
                    const enteredValue = e.target.value;
                    const trimmedValue = enteredValue.trim();
                    if (trimmedValue !== "" || enteredValue === "") {
                      setName(enteredValue);
                    }
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Email Id<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="email"
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  placeholder="Enter Employee Email Id"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="off"
                />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Contact No<span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  type="tel"
                  className="py-[10px] xl:py-3 px-4 text-sm rounded-lg [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none bg-[#FFFCE1] outline-[#FCDD00]"
                  placeholder="Enter Employee Contact No"
                  id="contact"
                  name="contact"
                  value={contact}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9+]/g, ""))
                  }
                  onChange={(e) => setContact(e.target.value)}
                  required
                  autoComplete="off"
                  // maxLength={10}
                />
              </div>
              <div className="flex justify-end">
                <button
                  onSubmit={handleSave}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] w-[100px] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
export const actionTypes = {
  SET_LOGIN: "SET_LOGIN",
  LOADER: "LOADER",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
};

export const LoginLoader = () => {
  return {
    type: actionTypes.LOADER,
    loader: true,
  };
};

// login
export const UserLogin = (contactno, password, login_type, type, history) => {
  const Data = {
    contactno: contactno,
    password: password,
    login_type: login_type,
    type: type,
  };
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/admin/login`, Data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_LOGIN,
          login: response?.data,
          loader: false,
        });
        
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        localStorage.setItem("Admin", JSON.stringify(response.data));
        if (response.data.data.role === "Admin") {
          history("/admin/admin_user/corporate");
        } else if (response.data.data.role === "Hr") {
          if(response.data.data.corporateName === 'Vibhuti'){
            history("/hr/employee");
          }else{
            history("/hr/uploademployee");
          }
        } else if (response.data.data.role === "Corporate") {
          history("/corporate/hr");
        } else if (response.data.data.role === "Doctor") {
          history("/doctor/employee-list")
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.SET_LOGIN,
          loader: false,
        });
      });
  };
};

//forget password

export const ForgetPassword = (Data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/forgetpass`, Data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.FORGET_PASSWORD,
          forgetpassword: response?.data,
          loader: false
        })
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
      })

      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.LOADER,
          loader: false,
        });
      });
  };
};

// for change password
export const ChangePasswords = (Data) => {
  return async (dispatch) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("_id");
    const logintype = urlParams.get("logintype");
    await axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}/changepass?_id=${id}&logintype=${logintype}`,
        Data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {dispatch({
          type: actionTypes.CHANGE_PASSWORD,
          changepassword: response.data,
          loader: false,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        
      })

      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.LOADER,
          loader: false,
        });
      });
  };
};

// for rest password for hr 

export const ResetPassword = (Data,history) => {
 
  return async (dispatch) => {
    const userData = localStorage.getItem("Admin");
    const logintype = JSON.parse(userData)?.data?.role;
    // Set a default URL
    let apiURL = `${process.env.REACT_APP_API_BASE_URL}/resetpassword`;

    if (logintype === "Corporate") {
      const _id = JSON.parse(userData)?.data?.corporateID;
      apiURL = `${apiURL}?_id=${_id}&logintype=${logintype}`;
    } else if (logintype === "Hr") {
      const _id = JSON.parse(userData)?.data?.hrID;
      apiURL = `${apiURL}?_id=${_id}&logintype=${logintype}`;
    } else if (logintype === "Doctor") {
      const _id = JSON.parse(userData)?.data?.hrID;
      apiURL = `${apiURL}?_id=${_id}&logintype=${logintype}`;
    }

    try {
      const response = await axios.patch(
        apiURL,
        Data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      dispatch({
        type: actionTypes.RESET_PASSWORD,
        resetpassword: response.data,
        loader: false,
      });
      if (response.data.code === 200) {
        await Swal.fire({
          title: "Your password has been updated successfully.",
          icon: "success",
          iconColor: "#FCDD00",
          confirmButtonText: "Login",
          confirmButtonColor: "#FCDD00",
          customClass: {
            confirmButton: "custom-confirm-button",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              history("/login");
            }, 0);
          }
        });
      }
      
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 5000,
      });

      dispatch({
        type: actionTypes.LOADER,
        loader: false,
      });
    }
  };
};

// change password for employee 
export const EmpChangePasswords = (Data,id) => {
  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/employee/changepassword/${id}`,
        Data,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((response) => {dispatch({
          type: actionTypes.CHANGE_PASSWORD,
          changepassword: response.data,
          loader: false,
        });
        if(response.data.code === 200){
          Swal.fire({
            title: "The password has been changed successfully. Please log in to the mobile app using your new password.",
            icon: "success",
            iconColor: "#FCDD00",
            confirmButtonText: "Ok",
            confirmButtonColor: "#FCDD00",
            customClass: {
              confirmButton: "custom-confirm-button",
            },
          })
        }
      })

      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type: actionTypes.LOADER,
          loader: false,
        });
      });
  };
};






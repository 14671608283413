export default function Loader(props) {
  return (
    <div>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="text-[#FCDD00] text-center text-lg font-Poppins font-medium tracking-[2px]">Loading...</div>
    </div>
  );
}

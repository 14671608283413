import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import react icon
import { MdClose } from "react-icons/md";
import { UpdateAdminEmployee } from "../../../Redux/Action/EmployeeAction";
import { useLocation } from "react-router";


//****************************************************edit lab model
export const EditEmployeeModal = ({ setEditEmpModel , userDetail  }) => {
  const dispatch = useDispatch();
  const [corpName, setCorpName] = useState("");
  const [empName, setEmpName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [contactNo, setContactNo] = useState("");
  const location = useLocation();

  useEffect(() => {
    if(userDetail){
        setCorpName(userDetail?.corporateName[0]);
        setEmpName(userDetail?.name);
        setEmailId(userDetail?.email);
        setContactNo(userDetail?.contact);
    }
  }, [userDetail]);


  const handleUpdate = async (e) => {
    e.preventDefault();
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(UpdateAdminEmployee(empName, emailId, contactNo , {...object} ));
    setEditEmpModel(false);
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-[100] bg-opacity-50">
        <div className="w-[350px] sm:w-[450px]   bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Edit Employee</div>
            <button
              onClick={() => {
                setEditEmpModel(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleUpdate}>
            <div className="p-3 xl:p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                   Corporate Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Your Name"
                    id="name"
                    name="name"
                    value={corpName}
                    disabled
                    autoComplete="off"
                    maxLength={50}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                  Employee Name<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="text"
                    placeholder="Enter Your URL"
                    value={empName}
                    onChange={(e) => setEmpName(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                   Email Id<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="email"
                    placeholder="Enter Your URL"
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    required
                    autoComplete="off"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <span className="font-Poppins text-[15px] xl:text-base">
                   Contact No<span className="text-[#fc0000]">*</span> :
                  </span>
                  <input
                    className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                    type="number"
                    placeholder="Enter Your URL"
                    value={contactNo}
                    disabled
                    autoComplete="off"
                  />
                </div>
              
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer w-[100px] outline-none"
                >
                  save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
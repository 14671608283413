import React from "react";

const AboutUs = () => {
  return (
    <div className=" mx-auto">
    <div className='w-[100%] bg-[#FFFCE1] flex items-center justify-center'>
  <div className="container py-10 flex flex-col items-center justify-between w-full px-4 ">
    <p className="text-3xl text-[#1A1A1A] pb-3 font-bold bg-white pt-2 mb-2 w-full text-center">
      About Us
    </p>
    <p className="text-[18px] text-[#1A1A1A] pb-3 font-semibold text-center mt-8">
      WelcomeCure - Pioneering Holistic Health Solutions
    </p>
    <p className="text-[18px] pb-3 text-center">
      🌿WelcomeCure, the epitome of holistic health and well-being!🌿
    </p>
    <p className="text-[16px] text-[#1A1A1A] pb-3 text-center">
      In a world where health knows no boundaries, WelcomeCure emerges as
      the beacon of comprehensive wellness, defying the constraints of
      location.
    </p>
    <p className="text-[16px] text-[#1A1A1A] pb-3 text-center">
      We invite you to embark on a journey towards holistic health and
      complete rejuvenation with us.Visit us at{" "}
      <a
        href="https://www.welcomecure.com/"
        className="hover:underline text-blue-500"
      >
        https://www.welcomecure.com/
      </a>{" "}
      and embrace a life of vitality, balance, and harmony.
    </p>
  </div>
</div>

      <div className=' w-[100%] py-10 ' >
      <div className="container md:flex mx-auto w-[100%] ">
        <div className="w-full md:w-[50%]  items-center px-4 justify-between">
          <p className="text-[18px] text-[#1A1A1A] pb-3 font-semibold text-center md:text-start">
          🌍WelcomeCure: Where Global Expertise Meets Holistic Healing🌍
          </p>
          <p className="text-[16px] text-[#1A1A1A] pb-3 text-center md:text-start">
            At WelcomeCure, we have fused the age-old wisdom of Homeopathy with
            modern healthcare principles to create a seamless sanctuary for
            disease treatment, prevention, and wholesome health maintenance. Our
            commitment to holistic healing knows no bounds, transcending
            geographical borders to touch the lives of individuals far and wide.
          </p>
        </div>
        <div className="w-full md:w-[50%] px-4 items-center text-center md:text-start ">
          <p className="text-[18px] text-[#1A1A1A] pb-3 font-semibold">
          🏥Our Proud Legacy: 4 Decades of Excellence in Healthcare🏥
          </p>
          <p className="text-[16px] text-[#1A1A1A] pb-3 text-center md:text-start">
            With over four decades of unwavering dedication to the field of
            Homeopathy and healthcare, WelcomeCure stands tall as the industry
            leader in providing holistic health solutions. Our legacy is a
            testament to our passion for promoting holistic well-being.
          </p>
        </div>
      </div>
      </div>
      <div className='w-[100%]  bg-[#FFFCE1] py-10'>
      <div className="container mx-auto   px-4">
        <p className="text-[18px] underline font-semibold pb-5">
        🌟Our Diverse Range of Services🌟
        </p>
        <div>
          <p className="flex text-[16px]">
            <span className="font-bold">🩺 Consult a Doctor:</span>
          </p>
          <p className="">
            {" "}
            Experience personalized care at your fingertips. Connect with
            dedicated professionals for expert guidance and support on your
            journey to better health.
          </p>
          <p className="pl-10">
            <span className="font-bold"> • Consult an Allopathy Doctor:</span>
            Access cutting-edge medical expertise with our Allopathy Doctor
            Service. Connect with experienced physicians who offer best
            treatments and recommendations to address your health concerns
            effectively.
          </p>
          <p className="pl-10">
            <span className="font-bold">• Consult a Homeopathy Doctor:</span>{" "}
            Experience holistic healing with our Homeopathy Doctor Service.
            Connect with skilled homeopathic practitioners who provide
            personalized, individualized remedies to promote your well-being and
            treat your ailments gently and comprehensively.
          </p>
          <div>
            <p className="flex text-[16px]">
              <span className="font-bold"> 📊 Health Reports:</span>
            </p>
            <p className="">
              {" "}
              Get essential updates for informed health decisions and early
              detection.Access detailed updates, empowering you to make informed
              decisions about your health and wellness.
            </p>
            <p>
              These reports play a significant role in aiding early diagnosis
              and detection of health issues, ensuring that potential problems
              are identified and addressed promptly.
            </p>
          </div>
          <div>
            <p className="flex text-[16px]">
              <span className="font-bold"> 🧪 Book a Lab Test:</span>
            </p>
            <p className="">
              {" "}
              Easily book tests with reliable labs, ensuring your health needs
              are met conveniently and cost-effectively. This helps you get the
              necessary tests done promptly and efficiently while keeping your
              expenses in check.
            </p>
          </div>
          <div>
            <p className="flex text-[16px]">
              <span className="font-bold"> 📅 Weekly Health Tips:</span>
            </p>
            <p className="">
              {" "}
              Enhance your well-being with expert insights delivered to you
              every week. Stay on top of your health game with the latest
              advice. Share it with your loved ones, spread smiles and good
              health.
            </p>
          </div>
          <div>
            <p className="flex text-[16px]">
              <span className="font-bold"> 📬 Monthly Health Newsletter:</span>
            </p>
            <p className="">
              {" "}
              Keep up with the ever-evolving world of health trends. Discover
              new health trends, nutritional information and so much more with
              our health trends.
            </p>
          </div>
          <div>
            <p className="flex text-[16px]">
              <span className="font-bold"> 💼 Hommeds - Health Kits:</span>
            </p>
            <p className="">
              {" "}
              Discover tailored homeopathic health solutions for 15+ conditions
              at <a
            href=" www.hommeds.com "
            className="hover:underline text-blue-500 "
          >
            www.hommeds.com
          </a> Explore holistic approaches to your health
              with our curated kits.
            </p>
          </div>
        </div>
      </div>
      </div>
      <div className='w-[100%]'>
      <div className="container md:flex mx-auto w-[100%] py-10 px-4">
        <div className="w-full md:w-[50%]  ">
          <p className="text-2xl text-[#1A1A1A] pb-3 font-semibold text-center md:text-start">
          🌐Breaking Geographical Barriers, Bridging Health Gaps🌐
          </p>
          <p className="text-[16px] text-[#1A1A1A] pb-3 text-center md:text-start">
          WelcomeCure's corporate health app is meticulously crafted to provide you with unparalleled access to premium healthcare facilities, irrespective of your geographical location. We bring the world of holistic health to your fingertips.
          </p>
        </div>
        <div className="w-full md:w-[50%] md:ml-6  items-center ">
          <p className="text-2xl text-[#1A1A1A] pb-3 font-semibold text-center md:text-start">
          💖WelcomeCure: Where Wholesome Health Meets Happiness💖
          </p>
          <p className="text-[16px] text-[#1A1A1A] text-center md:text-start">
          A healthy person is a happy person, and at WelcomeCure, we are committed to making every individual's journey towards well-being a joyful and fulfilling one. Your health is our mission, and your happiness is our reward.
          </p>
          <p className="text-[16px] text-[#1A1A1A] pb-3 text-center md:text-start">Join us on this remarkable voyage towards holistic health. Welcome to the future of holistic healthcare. Welcome to WelcomeCure! 🌿</p>
        </div>
      </div>
      </div>
      
    </div>
  );
};

export default AboutUs;

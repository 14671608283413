export const actionTypes = {
  SELECTED_IMAGE: "SELECTED_IMAGE",
  NEW_IMAGE_LIST: " NEW_IMAGE_LIST",
  SINGLE_IMAGE: "SINGLE_IMAGE",
  LOADER: "LOADER",
};

const initialState = {
  selectedimage: [],
  Imglist: [],
  singleIamge: [],
  imageloader: false,
};

const healthtipsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        imageloader: action.imageloader,
      };
    case actionTypes.SELECTED_IMAGE:
      return {
        ...state,
        selectedimage: action.selectedimage,
        imageloader: action.imageloader
      };
    case actionTypes.NEW_IMAGE_LIST:
      return {
        ...state,
        Imglist: action.Imglist,
      };
      case actionTypes.SINGLE_IMAGE:
      return {
        ...state,
        singleIamge: action.singleIamge,
      };
    default:
      return state;
  }
};

export default healthtipsReducer;

import React, { useState } from "react";
import { useEffect } from "react";
import CorporateLayout from "../../component/layout/CorporateLayout/CorporateLayout";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
//import action file
import { GetCorporateOptionlist } from "../../Redux/Action/CorporateAction";
import {
  fileLoader,
  resetForm,
  uploadCorporateEmployee,
} from "../../Redux/Action/UploadEmployeeAction";
//import icon
import { RiAddLine } from "react-icons/ri";
import Loader from "../../component/Admin/Loader";
//import modal
import { CorpoEmpModal } from "../../component/Modal/CorporateModal/CreateModal";

const CreateEmployee = () => {
  const dispatch = useDispatch();

  const [Createemployeemodel, setCreateemployeemodel] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCorporate, setSelectedCorporate] = useState("");

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  };
  //get token from local storage
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;

  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);

  useEffect(() => {
    if (user) {
      setSelectedCorporate(newcorporate?.data?.corporateID);
    }
  }, [user]);

  //for upload file
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      dispatch(selectedFile("please select a file"));
      return;
    }
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("corporateID", newcorporate?.data?.corporateID);
      dispatch(uploadCorporateEmployee(formData, token));
      dispatch(fileLoader());
      dispatch(selectedCorporate(selectedCorporate));
      dispatch(resetForm());
      setSelectedFile(null);
    } catch (error) {
      // console.log("error");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/xlsx": [".xls", ".xlsx"],
    },
  });

  const [optionList, setOptionList] = useState([]);

  const Corporatename = useSelector((state) => state?.corporateData?.CorporateOptionlist?.data);

  useEffect(() => {
    const con =
      Corporatename &&
      Corporatename?.map((c) => ({
        value: c.corporateID,
        label: c.corporateName,
      }));
    setOptionList(con);
  }, [Corporatename]);

  useEffect(() => {
    dispatch(GetCorporateOptionlist());
  }, []);

  //for download sample file
  // const handleDownloadSample = () => {
  //   const fileURL =
  //     "https://welcomcure-corporate-image.b-cdn.net/corporate/SampleFile.xlsx";
  //   const link = document.createElement("a");
  //   link.href = fileURL;
  //   link.download = "sample.xlsx";
  //   link.addEventListener("error", (e) => {
  //     console.error("Error occurred while downloading the file:", e);
  //   });
  //   link.click();
  // };

  // for searchable-dropdwon css
  const customStyles = {
    indicatorSeparator: () => ({}),
    control: (base) => ({
      ...base,
      background: "#FFFCE1",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#1A1A1A",
      fontFamily: "Roboto",
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#FCDD00" : "#808080",
      "&:hover": {
        borderColor: "#FCDD00",
      },
      padding: 3,
      border: "0px solid black",
      fontSize: 14,
      outline: "none",
      borderRadius: "8px",
      boxShadow: "none !important",
      backgroundColor: "#FFFCE1",
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
  };

  const loader = useSelector((state) => state?.employee?.fileloader);

  return (
    <>
      <CorporateLayout>
        {/* loader */}
        {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-20 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
        {/* emp create modal  */}
        {Createemployeemodel && (
          <CorpoEmpModal {...{ setCreateemployeemodel }} />
        )}

        <div className="flex flex-col h-full p-5">
          {/* --------------------------------------------Search Bar---------------------------------------------------------- */}
          <div className="bg-white p-4 rounded-lg  w-full mb-5">
            <div className="md:flex justify-between items-center">
              {/* filter div */}
              <div className=" flex items-center gap-2">
                <div>
                  <div className="flex flex-col gap-2 justify-end items-end">
                    <div className="w-56">
                      <Select
                        className=""
                        options={optionList}
                        placeholder="Select Corporate"
                        value={optionList?.filter(
                          (option) => option.value === selectedCorporate
                        )}
                        // onChange={handleSelect}
                        isSearchable={true}
                        styles={customStyles}
                        isDisabled
                      />
                      <div>
                        {Error && <p className="text-red-500">{Error}</p>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* create employee div  */}
              <div className="mt-3 md:mt-0">
                <button
                  onClick={() => setCreateemployeemodel(true)}
                  className="bg-[#FCDD00] text-[#1A1A1A] px-3 py-[6px] rounded-lg font-Poppins text-lg flex items-center gap-1 cursor-pointer  hover:scale-105"
                >
                  <span className="text-2xl">
                    <RiAddLine />
                  </span>
                  Add
                </button>
              </div>
            </div>
          </div>
          {/* --------------------------------------------Content Table------------------------------------------------------- */}
          <div className="w-full h-[333px] table-hieght  flex flex-col gap-3">
            <div className=" text-xl text-[#1A1A1A] font-Roboto">
              Upload your filled employee list file here ( .xls, .xlsx):
              <span className="text-[#fc0000] ml-1"> *</span>
            </div>
            {/*---------------- For Select File-------------- */}
            <div className=" cursor-pointer">
              <div
                {...getRootProps({
                  className:
                    "flex flex-col items-center justify-center border-2 border-[#808080] border-dashed p-3 py-10 md:p-16 rounded-lg bg-white",
                })}
              >
                <input {...getInputProps()} />
                <div>
                  <svg
                    className="w-8 h-8 mb-4 text-[#808080]"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                </div>
                <p className="text-sm text-[#808080] font-Roboto text-center">
                  Click to upload the employee list file OR drag & drop the file
                  here.
                </p>
                {selectedFile && (
                  <p className=" text-[#808080] font-Roboto mt-3 text-center">
                    Selected File :{selectedFile.name}
                  </p>
                )}
              </div>
            </div>

            {/* -----------------------Button--------------------- */}
            <div className="md:flex gap-5 justify-center mt-5 mx-auto">
              <div>
                <a
                  // onClick={handleDownloadSample}
                  href="https://welcomcure-corporate-image.b-cdn.net/corporate/SampleFile.xlsx"
                  className="border border-[#FCDD00] text-[#1A1A1A] bg-white w-[178px] py-2 rounded-lg font-Roboto flex justify-center"
                  download
                >
                  Download Sample File
                </a>
              </div>
              <div className="flex justify-center mt-3 md:mt-0">
                {selectedFile && (
                  <button
                    onClick={handleSubmit}
                    className="bg-[#FCDD00] text-[#1A1A1A] px-10 py-2 rounded-lg font-Roboto w-[170px] outline-none"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </CorporateLayout>
    </>
  );
};

export default CreateEmployee;
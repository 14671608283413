import React, { useState } from 'react'
import AdminLayout from '../../component/layout/AdminLayout/AdminLayout';
import { RiSearch2Line } from 'react-icons/ri'
import { useDropzone } from 'react-dropzone';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';

const WellnessPartner = () => {

    const [table, setTable] = useState(true)
    const [selectedOption, setSelectedOption] = useState('');
    const [otherOption, setOtherOption] = useState('');
    const [networkFiles, setNetworkFiles] = useState([]);
    const [packageFiles, setPackageFiles] = useState([]);
    const [packageName, setPackageName] = useState('');
    const [networkName, setNetworkName] = useState('');
    const [patnerName, setPatnerName] = useState('');
    const [cashLimit, setCashLimit] = useState('');

    const onNetworkDrop = (acceptedFiles) => {
        setNetworkFiles([...networkFiles, ...acceptedFiles]);
    };

    const onPackageDrop = (acceptedFiles) => {
        setPackageFiles([...packageFiles, ...acceptedFiles]);
    };
    const removeFile = (index, fileType) => {
        if (fileType === 'network') {
            const networkFile = [...networkFiles];
            networkFile.splice(index, 1);
            setNetworkFiles(networkFile);
        } else if (fileType === 'package') {
            const packageFile = [...packageFiles];
            packageFile.splice(index, 1);
            setPackageFiles(packageFile);
        }
    };

    const {
        getRootProps: getNetworkRootProps,
        getInputProps: getNetworkInputProps,
    } = useDropzone({
        onDrop: onNetworkDrop,
        accept: {
            'text/csv': ['.csv'],
        },
        multiple: true,
    });

    const {
        getRootProps: getPackageRootProps,
        getInputProps: getPackageInputProps,
    } = useDropzone({
        onDrop: onPackageDrop,
        accept: {
            'text/csv': ['.csv'],
        },
        multiple: true,
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            selectedOption,
            // otherOption,
            packageName,
            networkFiles,
            patnerName,
            cashLimit
        }
       console.log(formData);
    }

    return (
        <>
            <AdminLayout>
                <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px]">
                {table ? (
              <p className='font-Poppins text-[24px] font-medium mb-5'>
                Wellness Partner
              </p>
            ) : (
              <p className='font-Poppins text-[24px] font-medium mb-5'>
                <span onClick={() => setTable(true)} className='cursor-pointer'>Wellness Partner</span>
                <span className='text-[20px] font-normal'>&#62; Create Partner</span>
                <hr/>
              </p>
            )}
                    {/* --------------------------------------------Search Bar ---------------------------------------------------------- */}
                    <div  className= {`${table ? 'bg-white p-4 rounded-lg  w-full mb-5':'hidden'}`} >
                        <div className='md:flex gap-3 md:gap-0 justify-between items-center'>
                            <div className='relative mt-3 md:mt-0'>
                                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                                    <RiSearch2Line className="w-6 h-6" />
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <input
                                        className="h-full bg-[#FFFCE1] border rounded-[8px] py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium outline-[#FCDD00]"
                                        placeholder="Search By Name"
                                    />
                                </form>
                            </div>
                            <div className="mt-3 md:mt-0  w-[200px] xl:w-[300px] flex md:justify-end">
                                <button
                                    onClick={() => setTable(false)}
                                    className="bg-[#FCDD00] text-[#1A1A1A] px-3 py-[6px] rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer  hover:scale-105 w-[80px] text-center "
                                >
                                    +Add 
                                </button>
                            </div>
                        </div>
                    </div>

                    {table ? (<div className="w-full h-[520px] xl:h-[610px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
                        <table
                            className="corporate-table w-[100%]  text-left font-Poppins"
                            align="center"
                        >
                            <thead>
                                <tr className="z-20">
                                    <th><div className="pl-[40px] py-3 xl:py-5 min-w-[320px]">Name</div></th>
                                    <th><div className="min-w-[300px]">Created Date</div></th>
                                    <th><div className="min-w-[180px] justify-end flex pr-[40px]">Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='pl-[40px] py-3 xl:py-5 min-w-[320px]'>beact</td>
                                    <td>01/01/2024</td>
                                    <td className=' pr-[48px]'>
                                        <div className='flex justify-end gap-2 text-xl'>
                                            <span><BiEdit /></span>
                                            <span><AiOutlineDelete /></span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>) : (<div className='2div'>
                        <form>
                            <div className='flex gap-3'>
                                <div className='flex gap-2 items-center'>
                                    <label className='font-Roboto font-medium text-lg'>Test Lab</label>
                                    <input
                                        type='radio'
                                        name='partnerType'
                                        value='Test Lab'
                                        checked={selectedOption === 'Test Lab'}
                                        onChange={(e) => {
                                            setSelectedOption(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <label className='font-Roboto font-medium text-lg'>Dental Care</label>
                                    <input
                                        type='radio'
                                        name='partnerType'
                                        value='Dental Care'
                                        checked={selectedOption === 'Dental Care'}
                                        onChange={(e) => {
                                            setSelectedOption(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <label className='font-Roboto font-medium text-lg'>Vision Care</label>
                                    <input
                                        type='radio'
                                        name='partnerType'
                                        value='Vision Care'
                                        checked={selectedOption === 'Vision Care'}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                    />
                                </div>
                                <div className='flex gap-2 items-center'>
                                    <label className='font-Roboto font-medium text-lg'>Others</label>
                                    <input
                                        type='radio'
                                        name='partnerType'
                                        value='Other'
                                        checked={selectedOption === 'Other'}
                                        onChange={(e) => {
                                            setSelectedOption(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className=''>
                                    {selectedOption === 'Other' && (
                                        <input
                                            type='text'
                                            value={otherOption}
                                            onChange={(e) => setOtherOption(e.target.value)}
                                            className="py-[10px] xl:py-3 px-4 rounded-lg text-sm outline-[#FCDD00] min-w-[300px] border border-[#E8E7E7]"
                                            placeholder='Enter other option'
                                        />)}
                                </div>
                            </div>
                            <div className='flex flex-col gap-3 mt-5 '>
                                <div className='flex gap-3'>
                                    <label className='font-Roboto font-normal text-lg'>Enter Partner Name :</label>
                                    <input
                                        type='text'
                                        className="py-[10px] xl:py-3 px-4 rounded-lg  text-sm outline-[#FCDD00] min-w-[300px] border border-[#E8E7E7]"
                                        value={patnerName}
                                        onChange={(e) => {setPatnerName(e.target.value)}}
                                    />
                                </div>
                                <div className='flex gap-3'>
                                    <label className='font-Roboto font-normal text-lg'>CashlessLimit :</label>
                                    <input
                                        className="py-[10px] xl:py-3 px-4 rounded-lg text-sm outline-[#FCDD00] min-w-[300px] border border-[#E8E7E7]"
                                        placeholder='Enter amount limit'
                                        value={cashLimit}
                                        onChange={(e) => {setCashLimit(e.target.value)}}
                                    />
                                </div>
                            </div>
                            <div className='flex w-full gap-12 my-8'>
                                <div className='basis-1/2'>
                                    <h2 className='font-Poppins font-semibold text-2xl text-center'>Add Packages</h2>
                                    <input
                                        type='text'
                                        className="py-[10px] xl:py-3 px-4 rounded-lg  text-sm outline-[#FCDD00] w-full my-4 border border-[#E8E7E7]"
                                        placeholder='Enter Package Name'
                                        value={packageName}
                                        onChange={(e) => { setPackageName(e.target.value) }}
                                    />
                                    <h3 className='font-Poppins font-medium text-[22px] text-center mb-4'>Packages</h3>
                                    <div className=" cursor-pointer">

                                        <div
                                            {...getPackageRootProps({
                                                className:
                                                    "flex flex-col items-center justify-center border-2 border-[#808080] border-dashed p-3 py-10  md:p-16 rounded-lg bg-white",
                                            })}
                                        >
                                            <input {...getPackageInputProps()} />
                                            <div>
                                                <svg
                                                    className="w-8 h-8 mb-4 text-[#808080]"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 16"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="text-sm text-[#808080] font-Roboto text-center">
                                                Upload Packages
                                            </p>

                                        </div>
                                    </div>
                                    {packageFiles.length > 0 && (
                                        <div className="mt-3">
                                            <ul>
                                                {packageFiles.map((file, index) => (
                                                    <li key={index} className="flex gap-4 items-center">
                                                        <span>{file.name}</span>
                                                        <span
                                                            onClick={() => removeFile(index, 'package')}
                                                            className="cursor-pointer hover:text-red-500"
                                                        >
                                                            <AiOutlineDelete />
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className='basis-1/2'>
                                    <h2 className='font-Poppins font-semibold text-2xl text-center'>Add Network List</h2>
                                    <input
                                        type='text'
                                        className="py-[10px] xl:py-3 px-4 rounded-lg  text-sm outline-[#FCDD00] w-full my-4 border border-[#E8E7E7]"
                                        placeholder='Enter Network Name'
                                        value={networkName}
                                        onChange={(e) => { setNetworkName(e.target.value) }}
                                    />
                                    <h3 className='font-Poppins font-medium text-[22px] text-center mb-4'>Network List</h3>
                                    <div className=" cursor-pointer">

                                        <div
                                            {...getNetworkRootProps({
                                                className:
                                                    "flex flex-col items-center justify-center border-2 border-[#808080] border-dashed p-3 py-10  md:p-16 rounded-lg bg-white",
                                            })}
                                        >
                                            <input {...getNetworkInputProps()} />
                                            <div>
                                                <svg
                                                    className="w-8 h-8 mb-4 text-[#808080]"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 20 16"
                                                >
                                                    <path
                                                        stroke="currentColor"
                                                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="text-sm text-[#808080] font-Roboto text-center">
                                                Upload List
                                            </p>

                                        </div>
                                    </div>
                                    {networkFiles.length > 0 && (
                                        <div className="mt-3">
                                            <ul>
                                                {networkFiles.map((file, index) => (
                                                    <li key={index} className="flex gap-4 items-center">
                                                        <span>{file.name}</span>
                                                        <span
                                                            onClick={() => removeFile(index, 'network')}
                                                            className="cursor-pointer hover:text-red-500"
                                                        >
                                                            <AiOutlineDelete />
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flex justify-center'>
                                <button type='submit' className="w-[100px] bg-[#FCDD00] text-[#1A1A1A]  px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none">Save</button>
                            </div>
                        </form>
                    </div>)}
                </div>
            </AdminLayout>
        </>

    )
}

export default WellnessPartner;

import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./Reducer/LoginReducer";
import CorporateReducer from "./Reducer/CorporateReducer";
import HrReducer from "./Reducer/HeReducer";
import EmployeeReducer from "./Reducer/EmployeeReducer";
import employeeReducer from "./Reducer/UploadEmployeeReducer";
import healthtipsReducer from "./Reducer/HealthTipsReducer";
import addlabReducer from "./Reducer/LabTestReducer";
import doctorReducer from "./Reducer/DoctorReducer";
import AddFamilyReducer from "./Reducer/AddFamilyReducer";
import ReportReducer from "./Reducer/ReportReducer";



export const store = configureStore({
  reducer: {
    Auth: LoginReducer,
    corporateData : CorporateReducer,
    Hrlist : HrReducer,
    Employelists : EmployeeReducer,
    employee : employeeReducer,
    HealthTips : healthtipsReducer,
    AddLabTest: addlabReducer,
    DoctorData: doctorReducer,
    AddFamily: AddFamilyReducer,
    ReportData: ReportReducer,
  },
});
import axios from "axios";
import { toast } from "react-toastify";
//action type file
export const actionTypes = {
  ADD_LAB: "ADD_LAB",
  LAB_LIST: "LAB_LIST",
  LOADER: "LOADER",
  LAB_DETAIL: "LAB_DETAIL",
  EDIT_LAB_DETAIL: "EDIT_LAB_DETAIL",
};

export const labLoader = () => {
  return {
    type: actionTypes.LOADER,
    labloader: true,
  };
};

export const uploadLabTest = (formData, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/labtest`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    toast.success(response.data.message, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch(GetLabList());
    dispatch({
      type: actionTypes.ADD_LAB,
      addlab: response.data,
      labloader: false,
    });
  } catch (error) {
    toast.error(error.response.data.error, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type: actionTypes.LOADER,
      labloader: false,
    });
  }
};

//for lab list
export const GetLabList = (query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/labtest`, {
        params: { ...query },
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.LAB_LIST,
          lablist: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Lab Detail

export const GetLabDetail = (_id) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/labtest/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.LAB_DETAIL,
          labdetail: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// update lab status

export const UpdateLabTest = (_id, status, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  const data = {
    status: status,
  };
  return async (dispatch) => {
    await axios
      .put(`${process.env.REACT_APP_API_BASE_URL}/labtest/${_id}`, data, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetLabList(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

// delete lab

export const DeleteLab = (_id, query) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/labtest/${_id}`, {
        headers: {
          "x-access-token": token,
        },
      })
      .then((response) => {
        dispatch(GetLabList(query));
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};

//edit lab detail

export const EditLabTest = (_id, name, url, file) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  var data = new FormData();
  data.append("name", name);
  data.append("image", file);
  data.append("url", url);
  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/labtest/profile/${_id}`,
        data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        dispatch(GetLabList());
        toast.success(response?.data?.message, {
          theme: "colored",
          autoClose: 5000,
        });
        dispatch({
          type:actionTypes.EDIT_LAB_DETAIL,
          editlabdetail: response.data
        })
      })
      .catch((err) =>
        toast.error(err?.response?.data?.error, {
          theme: "colored",
          autoClose: 5000,
        })
      );
  };
};
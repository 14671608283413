import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect,useState } from "react";

//import icon
import { MdOutlineCorporateFare, MdManageAccounts } from "react-icons/md";
import { RiMentalHealthFill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { GiHealthNormal,GiHypodermicTest } from "react-icons/gi";
import arrow from "../../../images/Sidebar/arrow.svg";
import wellness from "../../../images/Sidebar/wellness.svg";
import userimg from "../../../images/Sidebar/adminuser.png";

const AdminSideBar = () => {
  const location = useLocation(); 
  const [openmenu, setOpenMenu] = useState(false);
  const [openaffiliatemenu, setopenaffiliatemenu] = useState(false);


  useEffect(() => {
    const isEmployeePage = location.pathname.includes("/admin/admin_user/uploadeemployee") || location.pathname.includes("/admin/admin_user/employee") || location.pathname.includes("/admin/export");
    setOpenMenu(isEmployeePage);
  }, [location.pathname]);

  useEffect(() => {
    const isAffiliatePage = location.pathname.includes("/admin/admin_user/panel");
    setopenaffiliatemenu(isAffiliatePage);
  }, [location.pathname]);

  return (
    <div className="lg:flex flex-no-wrap min-h-[91.8vh] hidden">
      <div className="min-w-[230px] bg-white  flex-col justify-between flex">
        <div>
          <ul className="px-3 pt-5 pb-10 flex flex-col gap-2 w-[231px]">
            <li>
              <NavLink
                to="/admin/admin_user/corporate"
                className="flex items-center gap-3 px-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <MdOutlineCorporateFare />
                Corporate
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/admin_user/hr"
                className="flex items-center gap-3 px-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <span className="text-xl">
                  <MdManageAccounts />
                </span>
                HR
              </NavLink>
            </li>
            <li>
              <div
               
                  onClick={() => setOpenMenu(!openmenu)}
                className={`flex items-center justify-between px-3  py-3 group rounded-[10px] cursor-pointer`}
              >
                <div className=" flex items-center gap-3">
                  <span>
                    <HiUserGroup />
                  </span>
                  <span className=" font-Roboto text-lg text-[#1A1A1A]">
                    Employee
                  </span>
                </div>
                <img
                  src={arrow}
                  alt="arrow"
                  // className={`${
                  //   openmenu ? 'rotate-180' : '' // Apply rotate-180 class if openmenu is true
                  // }`}
                ></img>
              </div>

      
            </li>
         {/*----------- Employee menu ------------------*/}

         {openmenu && (
              <div className="pl-[22px]">
                <NavLink
                  to="/admin/admin_user/uploadeemployee"
                  className="flex items-center font-Roboto text-base text-[#1A1A1A] py-3"
                >
                  Create Employee
                </NavLink>
                <NavLink
                  to="/admin/admin_user/employee"
                  className="flex items-center  font-Roboto text-base text-[#1A1A1A] py-3"
                >
                  Employee List
                </NavLink>
                <NavLink
                  to="/admin/export"
                  className="flex items-center  font-Roboto text-base text-[#1A1A1A] py-3"
                >
                  Export
                </NavLink>
              </div>
            )}

            {/*-----------Employee menu over ------------------*/}
           
            <li>
              <NavLink
                to="/admin/admin_user/doctor"
                className="flex items-center gap-3 px-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <GiHealthNormal/>
                Doctor
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/admin_user/healthtips"
                className="flex items-center gap-3 px-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <RiMentalHealthFill />
                Health Tips
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/admin_user/labtest"
                className="flex items-center gap-3 px-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <GiHypodermicTest />
                Book A Lab Test
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/admin/admin_user/wellness-partner"
                className="flex items-center gap-3 pl-3 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <img src={wellness} alt="wellness" />
                Wellness Partner
              </NavLink>
            </li>  

            <li>
              <div onClick={() => setopenaffiliatemenu(!openaffiliatemenu)}
                className={`flex items-center justify-between px-3  py-3 group rounded-[10px] cursor-pointer`}
              >
                <div className=" flex items-center gap-3">
                  <span>
                <img src={userimg} alt="wellness" className="w-[18px]" />
                  </span>
                  <span className=" font-Roboto text-lg text-[#1A1A1A]">
                    Affiliate
                  </span>
                </div>
                <img
                  src={arrow}
                  alt="arrow"

                ></img>
              </div>
            </li>

           {openaffiliatemenu && 
            <div className="pl-[22px]">
              <NavLink
                to="/admin/admin_user/panel"
                className="flex items-center font-Roboto text-base text-[#1A1A1A] py-3"
              >
                Vibhuti
              </NavLink>
              {/* <NavLink
                // to="/admin/admin_user/panel"
                className="flex items-center font-Roboto text-base text-[#1A1A1A] py-3"
              >
                Vibhuti Upload
              </NavLink> */}
               </div>
            }
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;

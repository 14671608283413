import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetEmployeelist, GetHREmployeelist } from "./EmployeeAction";
//action type file
export const actionTypes = {
  SELECT_CORPORATE: "SELECT_CORPORATE",
  SELECT_FILE: "SELECT_FILE",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "UPLOAD_FAILURE",
  RESET_FORM: "RESET_FORM",
  LOADER: "LOADER"
};

export const fileLoader =() => {
  return{
    type: actionTypes.LOADER,
  fileloader: true,
  }
}

export const selectFile = (file) => ({
  type: actionTypes.SELECT_FILE,
  payload: file,
});

export const resetForm = () => ({
  type: actionTypes.RESET_FORM,
});

export const uploadEmployee = (formData, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admin/employee`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    toast.success(response.data.message, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type: actionTypes.UPLOAD_SUCCESS,
      NewEmployee: response.data,
      fileloader:false,
     
    });
  } catch (error) {
    toast.error(error.response.data.error, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type:actionTypes.LOADER,
      fileloader:false
    })
  }
};

// create singale  employee

export const CreateEmployee = (Data, qurey) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/singleemployee`,
        Data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      dispatch(GetEmployeelist(qurey));
      toast.success(response?.data?.message, {
        theme: "colored",
        autoClose: 5000,
      });
      dispatch({
        type:actionTypes.LOADER,
        fileloader: false,
      })
      return response;
    } catch (err) {
      console.log(err,'err');
      toast.error(err?.response?.data?.error, {
        theme: "colored",
        autoClose: 5000,
      });
      dispatch({
        type:actionTypes.LOADER,
        fileloader: false,
      })
      throw err;
    }
  };
};

// create Hr  single employee

export const CreateHREmployee = (Data) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/hr/singleemployee`,
        Data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      dispatch({
        type:actionTypes.LOADER,
        fileloader: false,
      })
      toast.success(response?.data?.message, {
        theme: "colored",
        autoClose: 5000,
      });
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        theme: "colored",
        autoClose: 5000,
      });
      dispatch({
        type:actionTypes.LOADER,
        fileloader: false,
      })
      throw err;
    }
  };
};

export const uploadHrEmployee = (formData, token) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/hr/uploademployee`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );
    toast.success(response.data.message, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type: actionTypes.UPLOAD_SUCCESS,
      NewEmployee: response.data,
    });
  } catch (error) {
    toast.error(error.response.data.error, {
      autoClose: 5000,
      theme: "colored",
    });
    dispatch({
      type: actionTypes.UPLOAD_FAILURE,
      payload: error.response.data.error,
    });
  }
};

// create corporate singale  employee

export const CreateCorporateEmployee = (Data) => {
  const authToken = localStorage.getItem("Admin");
  const token = JSON.parse(authToken)?.data?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/singleemployee`,
        Data,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      toast.success(response?.data?.message, {
        theme: "colored",
        autoClose: 5000,
      });
      dispatch({
        type:actionTypes.LOADER,
        fileloader:false
      })
      return response;
    } catch (err) {
      toast.error(err?.response?.data?.error, {
        theme: "colored",
        autoClose: 5000,
      });
      dispatch({
        type:actionTypes.LOADER,
        fileloader:false
      })
      throw err;
    }
  };
};

export const uploadCorporateEmployee =
  (formData, token) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/corporate/employee`,
        formData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      toast.success(response.data.message, {
        autoClose: 5000,
        theme: "colored",
      });
      dispatch({
        type: actionTypes.UPLOAD_SUCCESS,
        NewEmployee: response.data,
      });
    } catch (error) {
      toast.error(error.response.data.error, {
        autoClose: 5000,
        theme: "colored",
      });
      dispatch({
        type: actionTypes.UPLOAD_FAILURE,
        payload: error.response.data.error,
      });
    }
  };
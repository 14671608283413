import { useDispatch, useSelector } from "react-redux";
//import react icone
import { MdClose } from "react-icons/md";
//import images
import Img_Report from "../../../images/Doctor/Img_Report.svg";
import Pdf_Report from "../../../images/Doctor/Pdf_Report.svg";
import Doc_Report from "../../../images/Doctor/Doc_Report.svg";
import { resetData } from "../../../Redux/Action/DoctorAction";

//report detail modal
export const ReportModal = ({ setOpenReportModal }) => {
  const dispatch = useDispatch()
  const reportDetail = useSelector((state) => state?.DoctorData?.docempreport?.data);

  const getFileType = (fileLink) => {
    if (fileLink.endsWith(".pdf")) {
      return "pdf";
    } else if (fileLink.endsWith(".jpg") || fileLink.endsWith(".png")) {
      return "img";
    } else if (fileLink.endsWith(".doc") || fileLink.endsWith(".docx")) {
      return "doc";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
        <div className="w-[330px] h-[450px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center ">
            <div className="text-[18px] xl:text-xl">Report</div>
            <button
              onClick={() => {
                dispatch(resetData())
                setOpenReportModal(false);
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <div className="h-[390px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100 relative">
            {reportDetail?.report?.length === 0 ? (
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-lg font-bold text-[#1A1A1A]">
                There is no record available.
              </p>
            ) : (
              reportDetail?.report?.map((emp, index) => (
                <div key={index}>
                  <div className="bg-[#FFFCE1] m-2 p-3 rounded-2xl text-[14px] max-w-[300px]">
                    <a
                      href={`${reportDetail?.storageurl}${emp.reportfile}`}
                      target="_blank"
                      className="flex gap-3 items-center"
                    >
                      <div>
                        {getFileType(emp.reportfile) === "pdf" ? (
                          <img src={Pdf_Report} alt="PDF" />
                        ) : getFileType(emp.reportfile) === "img" ? (
                          <img src={Img_Report} alt="Image" />
                        ) : (
                          <img src={Doc_Report} alt="Document" />
                        )}
                      </div>
                      <div>
                        {emp?.title?.length > 30 ? (
                          <div className="tooltip">
                            {`${emp?.title?.substring(0, 30)}...`}
                            <span className="tooltiptext">{emp.title}</span>
                          </div>
                        ) : (
                          <div>{emp.title}</div>
                        )}
                        <p>{emp?.date}</p>
                      </div>
                    </a>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

//family member detail modal

export const FamilyDetailModal = ({ setOpenFamilyDetailModal }) => {

  const dispatch = useDispatch()
  const FamilyData = useSelector((state) => state?.DoctorData?.docempfamily?.data);

  return (
    <>
      <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
        <div className=" h-[420px] min-w-[330px] md:w-[716px] bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px] overflow-hidden">
          <div className="rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center ">
            <div className="text-[18px] xl:text-xl">Family Details</div>
            <button
              onClick={() => {
                setOpenFamilyDetailModal(false);
                dispatch(resetData())
              }}
            >
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <div className="h-[367px] p-2">
            {FamilyData?.family?.length === 0 ? (
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-lg font-bold text-[#1A1A1A]">
                There is no record available.
              </p>
            ) : (
              <div className="h-full">
                <div className="hidden md:block h-[101%]">
                  <div className="h-[98%] max-w-[700px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100 ">
                    <table className="text-sm text-left rtl:text-right text-gray-500 w-full">
                      <thead className="text-xs text-gray-700 uppercase ">
                        <tr className="bg-[#FFFCE1]">
                          <th scope="col" className="px-6 py-2 ">
                            Name
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Relation
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Age
                          </th>
                          {/* <th scope="col" className="px-6 py-2">
                            Blood Group
                          </th> */}
                          <th scope="col" className="px-6 py-2 max-w-[242px]">
                            Email ID
                          </th>
                          <th scope="col" className="px-6 py-2">
                            Contact Number
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {FamilyData?.family?.map((fam, index) => (
                          <tr className="bg-white border-b" key={index}>
                            <td className="px-6 py-2">
                              {fam?.name?.length > 30 ? (
                                <span>{`${fam?.name?.substring(
                                  0,
                                  30
                                )}...`}</span>
                              ) : (
                                <div>{fam?.name}</div>
                              )}
                            </td>
                            <td className="px-6 py-2">
                              <div>{fam?.relation}</div>
                            </td>
                            <td className="px-6 py-2">
                              <div>{fam?.age}</div>
                            </td>
                            {/* <td className="px-6 py-2">
                              <div>{fam?.bloodgroup}</div>
                            </td> */}
                            <td className="px-6 py-2">
                              <div>{fam?.email}</div>
                            </td>
                            <td className="px-6 py-2">
                              <div>{fam?.contact}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* mobile view  */}
                <div className="md:hidden">
                  {FamilyData?.family?.map((fam, index) => (
                    <div key={index}>
                      <div className="flex justify-between items-center bg-[#FFFCE1] m-2 p-3 rounded-2xl text-[14px] max-w-[300px] font-Poppins">
                        <div>
                          <p className="font-bold">  {fam?.name?.length > 30 ? (
                          <div className="tooltip">
                            {`${fam?.name?.substring(0, 30)}...`}
                            <span className="tooltiptext">{fam.name}</span>
                          </div>
                        ) : (
                          <div>{fam.name}</div>
                        )}</p>
                          <p>{fam?.contact}</p>
                        </div>
                        <div>
                          <p className="text-gray-700">Age</p>
                          <p>{fam?.age}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};


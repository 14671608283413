import React, { Fragment, useEffect, useState } from "react";
import CorporateLayout from "../../component/layout/CorporateLayout/CorporateLayout";
import ReactPaginate from "react-paginate";
import {
  DeleteCorporateEmployee,
  EmpMailStatus,
  EmployeeLoader,
  GetCorporateEmployeeList,
  UpdateCorporateEmployee,
} from "../../Redux/Action/EmployeeAction";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
// import icon
import { AiOutlineDelete, AiOutlineMail } from "react-icons/ai";
import { RiSearch2Line } from "react-icons/ri";
import LimitArrow from "../../images/LimitArrow.svg";
import { Menu, Transition } from "@headlessui/react";
import Loader from "../../component/Admin/Loader";

const CorporateEmployee = () => {
  const location = useLocation();
  const history = useNavigate();
  const [selectpage, setselectpage] = useState("");

  const itemsPerPageOptions = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];

  const dispatch = useDispatch();
  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);

  useEffect(() => {
    searchParams.delete("search");
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, { ...object })
    );
  }, []);

  const Employeelist = useSelector(
    (state) => state?.Employelists?.CorporateEmployeList?.data
  );

  const [checkvalue, setcheckvalue] = useState("");
  const [Itemperpage, setItemperpage] = useState("");
  const [page, setpage] = useState("");
  const pageCount = Math.ceil(Number(Employeelist?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [Employeelist]);

  useEffect(() => {
    setpage(itemsPerPageOptions[0]?.value);
  }, []);

  // for select page data
  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      setselectpage(0)
      history({
        pathname: "/corporate/employee",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value, page:1 }).toString(),
      });
    } else {
      history({
        pathname: "/corporate/employee",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, {
        ...object,
        limit: item.value,
        page:1
      })
    );
  };

  // for pagination
  const handleFirstPage = () => {
    setselectpage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/corporate/employee",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/corporate/employee",
        search: `?page=1`,
      });
    }
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, {
        ...object,
        page: 1,
      })
    );
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/corporate/employee",
        search:
          "?" +
          new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/corporate/employee",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, {
        ...object,
        page: lastPage + 1,
      })
    );
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/corporate/employee",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/corporate/employee",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, {
        ...object,
        page: selected.selected + 1,
      })
    );
  };

  //for search filter
  const [Search, setSearch] = useState("");

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      delete (object.page);
      history({
        pathname: "/corporate/employee",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/corporate/employee",
        search: `?search=${Search}`,
      });
    }
    dispatch(
      GetCorporateEmployeeList(newcorporate?.data?.corporateID, {
        ...object,
        search: Search,
      })
    );
    setselectpage(object.page = 0);
  };



  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete (object.search)
    delete (object.page)
    history({
      pathname: "/corporate/employee",
      search:
        "?" +
        new URLSearchParams({
          ...object
        }).toString(),
    });
    dispatch(GetCorporateEmployeeList(newcorporate?.data?.corporateID, object));
    setselectpage(object.page = 0);
  };

  // for mail 
  const handelMail = (id) => {
    dispatch(EmpMailStatus(id,newcorporate?.data?.corporateID,));
    dispatch(EmployeeLoader())
  }

  // for delete
  const handleDelete = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Employee!",
      icon: "warning",
      iconColor: "#FCDD00",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#FCDD00",
      cancelButtonColor: "#FFFCE1",
      customClass: {
        confirmButton: "custom-confirm-button",
        cancelButton: "custom-cancel-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        if (
          Employeelist?.employeeData.length == 1 &&
          object.page != undefined &&
          object.page != 1
        ) {
          const currentPage = parseInt(object.page || 1, 10);
          history({
            pathname: "/corporate/employee",
            search: `?page=${currentPage > 1 ? currentPage - 1 : 1}`,
          });
          object.page = object.page - 1;
        }
        dispatch(
          DeleteCorporateEmployee(
            index,
            newcorporate?.data?.corporateID,
            object
          )
        );
      }
    });
  };

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, []);

  useEffect(() => {
    const newpage = searchParams.get("page");
    if (newpage?.length > 0 && newpage !== null) {
      setselectpage(newpage - 1);
    }
  }, []);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //for pagination
  const reactPaginate = {
    previousLabel: "<",
    nextLabel: ">",
    breakLabel: "",
    pageCount: Itemperpage,
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    previousLinkClassName: "pagination__link",
    nextLinkClassName: "pagination__link",
    disabledClassName: "pagination__link--disabled",
    activeClassName: "pagination__link--active",
    forcePage: selectpage,
  };

  const loader = useSelector((state) => state?.Employelists?.employeeloader)
  return (
    <>
     {loader && (
          <div>
            <div className="bg-black fixed  top-0 left-0 right-0 w-full h-full z-40 bg-opacity-50">
              <div className="z-[50] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <Loader />
              </div>
            </div>
          </div>
        )}
      <CorporateLayout>
        <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px] 2xl:justify-between">
          {/* --------------------------------------------Search Bar---------------------------------------------------------- */}
          <div className="bg-white p-4 mb-5 rounded-lg  w-full">
            <div className=" flex justify-between items-center">
              <div className="relative">
                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                  <RiSearch2Line className="w-6 h-6" />
                </div>

                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    if (Search.trim() !== "") {
                      submitsearch(e);
                    }
                  }}
                >
                  <input
                    className="h-full bg-[#FFFCE1] border rounded-[8px] py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium outline-[#FCDD00]"
                    placeholder="Search By Employee Name"
                    value={Search}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setSearch(inputValue);
                      if (inputValue.trim() === "") {
                        removeQueryParams();
                      }
                    }}
                  ></input>
                </form>
              </div>
            </div>
          </div>
          {/* --------------------------------------------Content Table------------------------------------------------------- */}
          <div className="w-full h-[520px] xl:h-[610px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            {Employeelist?.employeeData?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="font-Roboto text-xl ">
                  No Employee Details Available.
                </p>
              </div>
            ) : (
              <table className="corporate-table w-[100%]  text-left font-Poppins">
                <thead>
                  <tr className="z-10">
                    {/* <th><div className="pl-[40px] py-3 xl:py-5 min-w-[320px]">Corporate Name</div></th> */}
                    <th>
                      <div className="pl-[40px] py-3 xl:py-5 min-w-[200px]">
                        Employee Name
                      </div>
                    </th>
                    <th>
                      <div className="min-w-[200px]">Email Id</div>
                    </th>
                    <th>
                      <div className="min-w-[160px]">Contact No.</div>
                    </th>
                    <th>
                      <div className="min-w-[130px]">Status</div>
                    </th>
                    <th>
                      <div className="min-w-[120px]">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Employeelist?.employeeData?.map((user, index) => (
                    <tr key={index}>
                      {/* <td className="pl-[40px] py-2 xl:py-4 h-[46px]">
                        {user?.corporateName && user.corporateName[0] ? (
                          user.corporateName[0].length > 30 ? (
                            <div className="tooltip">
                              {" "}
                              <span>
                                {user.corporateName[0].substring(0, 30)}...
                              </span>
                              <span className="tooltiptext">
                                {user.corporateName[0]}
                              </span>
                            </div>
                          ) : (
                            <span>{user.corporateName[0]}</span>
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </td> */}

                      {user?.name?.length > 30 ? (
                        <td className="pl-[40px] py-2 xl:py-4 h-[46px]">
                          <div className="tooltip capitalize">
                            {`${user?.name?.substring(0, 30)}...`}
                            <span className="tooltiptext">{user.name}</span>
                          </div>
                        </td>
                      ) : (
                        <td className="pl-[40px] py-2 xl:py-4 h-[46px]">
                          <div className="capitalize">{user.name}</div>
                        </td>
                      )}

                      {user?.email?.length > 35 ? (
                        <td>
                          <div className="tooltip">
                            {`${user?.email?.substring(0, 35)}...`}
                            <span className="tooltiptext">{user.email}</span>
                          </div>
                        </td>
                      ) : (
                        <td>
                          <div>{user.email}</div>
                        </td>
                      )}

                      <td>{user.contact}</td>
                      <td>
                        <div className=" flex items-center">
                          <div className=" flex flex-col items-center">
                            {/* Code block starts */}
                            <div className="cursor-pointer rounded-full bg-gray-200 relative shadow-sm">
                              <input
                                type="checkbox"
                                name="toggle"
                                id={user?._id}
                                defaultChecked={user.status == true}
                                role="switch"
                                onChange={(e) => {
                                  if (user?.status == true) {
                                    setcheckvalue(false);
                                  } else {
                                    setcheckvalue(true);
                                  }
                                  const sds = location.search
                                    .slice(1)
                                    .split("&");
                                  let object = {};
                                  if (location.search.includes("?")) {
                                    sds.map((item) => {
                                      let newItem = item.split("=");
                                      object[newItem[0]] = newItem[1];
                                    });
                                  }
                                  dispatch(
                                    UpdateCorporateEmployee(
                                      user?._id,
                                      user?.status == true ? false : true,
                                      user?.corporateID,
                                      { ...object, search: Search },
                                      newcorporate?.data?.corporateID
                                    )
                                  );
                                }}
                                value={checkvalue}
                                className={`${user.status == false ? "left-0" : "right-0"
                                  } focus:outline-none checkbox w-[22px] h-[22px] mx-[3px] rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto`}
                              />
                              <label
                                htmlFor={user?._id}
                                className={`${user.status == true
                                    ? "bg-[#fcdd00]"
                                    : "bg-[#dfdfdf]"
                                  } block w-[50px] r-2 h-[26px] overflow-hidden rounded-full  cursor-pointer`}
                              />
                            </div>
                          </div>
                          {/* Code block ends */}
                        </div>
                      </td>
                      <td>
                        <div className="flex">
                          {/* mail btn  */}
                          <span className="text-xl">
                              <AiOutlineMail
                                className="cursor-pointer"
                                onClick={() => handelMail(user?._id)}
                              />
                            </span>
                          {/* delete btn  */}
                          <div className="pl-4 hover:text-[#FF0000]">
                            <span className="text-xl">
                              <AiOutlineDelete
                                className="cursor-pointer"
                                onClick={() => handleDelete(user?._id)}
                              />
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {/* <ToastContainer /> */}
          </div>
          {/* --------------------------------------------Footer---------------------------------------------------------- */}
          <div className="footer-content mt-5">
            <div className="md:flex justify-between items-center">
              <div className="flex gap-2 items-center">
                <p className="capitalize">View</p>
                <div className="">
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                        <p className="text-[#47505F] font-medium font-[poppins]">
                          {page}
                        </p>
                        <img src={LimitArrow} alt="LimitArrow" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                        {itemsPerPageOptions?.map((e) => (
                          <div key={e?.id}>
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-[#FFFCE1] text-[#1A1A1A]" : "",
                                    "block px-2 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => onselectedpage(e)}
                                >
                                  {e?.value}
                                </p>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              {/* --------------------------------------------Footer Pagination---------------------------------------------------------- */}

              <div className="flex items-center mt-3 md:mt-0">
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleFirstPage}
                >
                  &lt;&lt;
                </button>
                <div className="hidden md:block">
                  <ReactPaginate
                    {...reactPaginate}
                    breakLabel="..."
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                  />
                </div>
                <div className="md:hidden">
                  <ReactPaginate
                    {...reactPaginate}
                    marginPagesDisplayed={0}
                    pageRangeDisplayed={1}
                  />
                </div>
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleLastPage}
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </CorporateLayout>
    </>
  );
};

export default CorporateEmployee;

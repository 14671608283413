import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HrLayout from "../../component/layout/HrLayout/HrLayout";
//import images
import calendar from "../../images/Report/Date.png";
import Report1 from "../../images/Report/Report_1.png";
import Report2 from "../../images/Report/Report_2.png";
import Report3 from "../../images/Report/Report_3.png";
import Report4 from "../../images/Report/Report_4.png";
import Report5 from "../../images/Report/Report_5.png";
import Report6 from "../../images/Report/Report_6.png";
import Report7 from "../../images/Report/Report_7.png";
//import action file
import { GetReportData } from "../../Redux/Action/ReportAction";
// react date picker npm
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import UseOutsideClick from "../../component/Admin/UseOutsideClick";

const Report = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  UseOutsideClick(ref, () =>{
    if(open){
      setopen(false)
    }
  });

  const [open, setopen] = useState(false);
  const [dateRangeText, setDateRangeText] = useState("Date");

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  function formatDate(date) {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }
  const handleSelection = (range) => {
    setSelectionRange(range.selection);
    const startDateText = formatDate(range.selection.startDate);
    const endDateText = formatDate(range.selection.endDate);
    if (startDateText === endDateText) {
      setDateRangeText(`${startDateText}`)
    }else{
      setDateRangeText(`${startDateText} - ${endDateText}`);
    }
  };

  const handleSend = () => {
    const { startDate, endDate } = selectionRange;
    const data = {
      start: new Date(startDate).valueOf(),
      end: new Date(endDate).valueOf(),
    };
    dispatch(GetReportData(data));
  };

  const ClearDateSend = () => {
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(GetReportData(""));
    setDateRangeText("Date");
  };

  useEffect(() => {
    dispatch(GetReportData(""));
  }, [dispatch]);

  const reportData = useSelector((state) => state?.ReportData?.reportdata?.data);

  return (
    <HrLayout>
      <div className="flex flex-col h-full p-5">
        {/* --------------------------------------------Search Bar---------------------------------------------------------- */}
        <div className="bg-white p-4 mb-5 rounded-lg  w-full">
          <div className=" flex justify-end items-center">
            <div className="mt-3 md:mt-0">
              <button
                onClick={() => setopen(!open)}
                className="bg-[#FCDD00] text-[#1A1A1A] px-5 py-[6px] rounded-lg font-Poppins text-lg flex items-center gap-1 cursor-pointer  hover:scale-105"
              >
                <span>
                  <img
                    src={calendar}
                    alt="date"
                    className="w-[20px] h-[20px]"
                  />
                </span>
                <span className="mt-[2px] text-[14px] md:text-lg">{dateRangeText}</span>
              </button>
              {/* for calendar modal */}
              {open && (
                <div className=" absolute right-[15px] top-[175px]  d-inline-block z-50 shadow-xl" ref={ref}>
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelection}
                    rangeColors={["#FCDD00"]}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    maxDate={new Date()}
                    popperProps={{
                      strategy: "fixed",
                    }}
                    direction="horizontal"
                  />
                  <div className="text-right position-relative  rdr-buttons-position pb-2 bg-[#fff]">
                    <button
                      className=" bg-[#FCDD00] py-1 text-[12px] text-[#fff] rounded-[5px] px-4 mr-2"
                      onClick={() => {
                        handleSend();
                        setopen(false);
                      }}
                    >
                      Apply
                    </button>
                    <button
                      onClick={() => {
                        ClearDateSend();
                      }}
                      className=" border border-[#FCDD00] text-[#FCDD00] py-1 text-[12px]  rounded-[5px] px-4 mr-2"
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
       {/* for Content Table */}
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report1} alt="report 1 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Total Employee</p>
                <p className="font-semibold text-[24px]">
                  {reportData?.employeetotal}
                </p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report2} alt="report 2 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Total Family Member</p>
                <p className="font-semibold text-[24px]">
                  {reportData?.familytotal}
                </p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report3} alt="report 3 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Total Employees Consultation</p>
                <p className="font-semibold text-[24px]">0</p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report4} alt="report 4 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Total Family Consultation</p>
                <p className="font-semibold text-[24px]">0</p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report5} alt="report 5 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Total Consultation</p>
                <p className="font-semibold text-[24px]">0</p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report6} alt="report 6 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Save Time</p>
                <p className="font-semibold text-[24px]">0</p>
              </div>
            </div>

            <div className="flex bg-white border py-[20px] px-[50px] border-[#E8E7E7] rounded-[16px]">
              <img src={Report7} alt="report 7 icon" className="mr-5 w-[50px] h-[50px]" />
              <div className="font-Poppins text-[18px] font-normal">
                <p>Save Money</p>
                <p className="font-semibold text-[24px]">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HrLayout>
  );
};

export default Report;

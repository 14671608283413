export const actionTypes = {
  EMPLOYE_LIST: "EMPLOYE_LIST",
  HR_EMPLOYE_LIST: "HR_EMPLOYE_LIST",
  CORPORATE_EMPLOYE_LIST: "CORPORATE_EMPLOYE_LIST",
  LOADER: "LOADER",
  DELETE_HR_EMPLOYEE: "DELETE_HR_EMPLOYEE",
  EMP_MAIL_STATUS: "EMP_MAIL_STATUS",
  EXPORT_DATA: "EXPORT_DATA",

};

const INITIAL_STATE = {
  EmployeList: [],
  HrEmployeList: [],
  CorporateEmployeList: [],
  employeeloader: false,
  deletehremp: [],
  EmpMailStatus: [],
  ExportData: [],
};

const EmployeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOADER:
      return {
        ...state,
        employeeloader: action.employeeloader,
      };
    case actionTypes.EMPLOYE_LIST:
      return {
        ...state,
        EmployeList: action.EmployeList,
        employeeloader: action.employeeloader,
      };
    case actionTypes.HR_EMPLOYE_LIST:
      return {
        ...state,
        HrEmployeList: action.HrEmployeList,
        employeeloader: action.employeeloader,
      };
    case actionTypes.CORPORATE_EMPLOYE_LIST:
      return {
        ...state,
        CorporateEmployeList: action.CorporateEmployeList,
        employeeloader: action.employeeloader,
      };
    case actionTypes.DELETE_HR_EMPLOYEE:
      return {
        ...state,
        deletehremp: action.deletehremp,
      };
    case actionTypes.EMP_MAIL_STATUS:
      return {
        ...state,
        EmpMailStatus: action.EmpMailStatus,
        employeeloader: action.employeeloader,
      };
    case actionTypes.EXPORT_DATA:
      return {
        ...state,
        ExportData: action.ExportData,
        employeeloader: action.employeeloader,
      };
    default:
      return state;
  }
};

export default EmployeeReducer;

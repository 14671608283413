import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import arrow from "../../../images/Sidebar/arrow.svg"

import { RiContactsBookUploadFill } from "react-icons/ri";
import { HiUserGroup } from "react-icons/hi";
import { TbReport } from "react-icons/tb";
import { MdFamilyRestroom } from "react-icons/md";
import { useEffect } from "react";

const HrSidebar = () => {
  const location = useLocation();
  const [openmenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const isEmployeePage = location.pathname.includes("/hr/uploademployee") || location.pathname.includes("/hr/employee");
    setOpenMenu(isEmployeePage);
  }, [location.pathname]); 
  
  const user = localStorage.getItem("Admin");
  const newcorporate = JSON.parse(user);
  return (
    <div className="lg:flex flex-no-wrap min-h-[91.8vh] hidden">
      <div className="min-w-[230px] bg-white  flex-col justify-between flex z-10">
        <div>
          <ul className="px-3 py-5 flex flex-col gap-2 min-w-[231px]">
            {/* <li>
              <NavLink
                to="/hr/uploademployee"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <RiContactsBookUploadFill /> Create Employee
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hr/employee"
                className="flex items-center gap-3 px-5 font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <HiUserGroup /> Employee List
              </NavLink>
            </li> */}
                     
         <li
               
               onClick={() => setOpenMenu(!openmenu)}
             >
               <div
                 className={`flex items-center justify-between px-[12px]  py-3 group rounded-[10px] cursor-pointer`}
               >
                 <div className=" flex items-center gap-3">
                   <span><HiUserGroup /></span>
                   <span className=" font-Roboto text-lg text-[#1A1A1A]">
                     Employee
                   </span>
                 </div>
                 <img src={arrow}
                  // className={`${openmenu ? `${'rotate-180'}` : `${'-rotate-360'}`}  `}
                  ></img>
               </div>
             </li>

              {/*----------- Employee menu ------------------*/}

              {openmenu && (
             <div className="pl-[22px]">
                  {newcorporate?.data?.corporateName !== 'Vibhuti' && <NavLink
               to="/hr/uploademployee"
               className="flex items-center font-Roboto text-base text-[#1A1A1A] py-3"
             >
               {/* <RiContactsBookUploadFill /> */}
               Create Employee
             </NavLink>}
             <NavLink
               to="/hr/employee"
               className="flex items-center  font-Roboto text-base text-[#1A1A1A] py-3"
             >
               {/* <HiUserGroup /> */}
               Employee List
             </NavLink>
               
             </div>
           )}

           {/*-----------Employee menu over ------------------*/}
            <li>
              <NavLink
                to="/hr/family-member"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <MdFamilyRestroom /> Family Member
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/hr/report"
                className="flex items-center gap-3 px-[12px] font-Roboto text-lg text-[#1A1A1A] py-3"
              >
                <TbReport /> Report
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HrSidebar;

import { MdClose } from "react-icons/md";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ResetPassword } from "../../../Redux/Action/LoginAction";
import { useNavigate } from "react-router";



export const ResetPassModal = ({handelClose}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [oldpassword, setOldPassword] = useState("");
    const [newpassword, setNewPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [newpasswordType, setNewpasswordType] = useState("password");
    const [conpasswordType, setConpasswordType] = useState("password");
    const [oldpasswordType, setOldpasswordType] = useState("password");
    const [error, seterror] = useState("");
    
  
    useEffect(() => {
      if (newpassword === confirmpassword && passwordError) {
        setPasswordError("");
      }
    }, [newpassword, confirmpassword, passwordError]);
  
    useEffect(() => {
      if (oldpassword !== newpassword && error) {
        seterror("");
      }
    }, [oldpassword, newpassword, error]);

    const toggleNewPassword = (e) => {
        e?.preventDefault();
        if (newpasswordType === "password") {
          setNewpasswordType("text");
          return;
        }
    
        setNewpasswordType("password");
      };
      const toggleConfirmPassword = (e) => {
        e?.preventDefault();
        if (conpasswordType === "password") {
          setConpasswordType("text");
          return;
        }
        setConpasswordType("password");
      };
      const toggleOldPassword = (e) => {
        e?.preventDefault();
        if (oldpasswordType === "password") {
          setOldpasswordType("text");
          return;
        }
        setOldpasswordType("password");
      };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const formData = {
        oldpassword,
        newpassword,
        confirmpassword,
      };
      if (newpassword !== confirmpassword) {
        setPasswordError(
          "The new password and the confirmed password must be the same."
        );
        return;
      }
      if (oldpassword === newpassword) {
        seterror("The new password must be different from the current password.");
        return;
      }
      dispatch(ResetPassword(formData, navigate));
    };


    return (
        <div
        className="bg-black fixed  top-0 left-0 right-0 bottom-0 w-full h-full black-bg bg-opacity-50"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="w-[340px] sm:w-[400px] md:w-[500px]  bg-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl rounded-[10px]">
          <div className=" rounded-t-2xl px-3 xl:px-5 py-2 xl:py-3 uppercase font-Poppins text-xl  flex justify-between items-center">
            <div className="text-[18px] xl:text-xl">Change Password</div>
            <button onClick={handelClose}>
              <MdClose className="text-gray-500 text-2xl hover:text-black" />
            </button>
          </div>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <div className="p-5 md:p-3 xl:p-5 flex flex-col gap-5">
              <div className="flex flex-col gap-2 relative">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Old Password
                  <span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  type={oldpasswordType}
                  placeholder="Enter Old Password"
                  id="corporateName"
                  name="corporateName"
                  value={oldpassword}
                  onChange={(e) =>{
                    const trimmedValue = e.target.value.trim();
                    setOldPassword(trimmedValue);
                  }}
                  required
                  autoComplete="off"
                  maxLength={50}
                />
                <div className="absolute right-4 top-[35px] xl:top-[41px] z-10 bg-[#FFFCE1] py-1">
                  <button onClick={toggleOldPassword}>
                    {oldpasswordType === "password" ? (
                      // <img src={eyeopen} alt="new" /> 
                      <BsEye/>
                    ) : (
                      // <img src={eyeclose} alt="new" />
                      <BsEyeSlash/>
                    )}
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2 relative">
                <span className="font-Poppins text-[15px] xl:text-base">
                  New Password
                  <span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  type={newpasswordType}
                  placeholder="Enter New Password"
                  value={newpassword}
                  onChange={(e) =>{
                    const trimmedValue = e.target.value.trim();
                    setNewPassword(trimmedValue)
                  }}
                  required
                  autoComplete="off"
                />
                <div className="absolute right-4 top-[35px] xl:top-[41px] z-10 bg-[#FFFCE1] py-1">
                  <button onClick={toggleNewPassword}>
                    {newpasswordType === "password" ? (
                      // <img src={eyeopen} alt="new" /> 
                      <BsEye/>
                    ) : (
                      // <img src={eyeclose} alt="new" />
                      <BsEyeSlash/>
                    )}
                  </button>
                </div>
              </div>
              <div className="flex flex-col gap-2 relative">
                <span className="font-Poppins text-[15px] xl:text-base">
                  Confirm Password
                  <span className="text-[#fc0000]">*</span> :
                </span>
                <input
                  className="py-[10px] xl:py-3 px-4 rounded-lg bg-[#FFFCE1] text-sm outline-[#FCDD00]"
                  type={conpasswordType}
                  placeholder="Enter Confirm Password"
                  id="email"
                  name="email"
                  value={confirmpassword}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trim();
                    setConfirmPassword(trimmedValue)
                  }}
                  required
                  autoComplete="off"
                />
                <div className="absolute right-4 top-[35px] xl:top-[41px] z-10 bg-[#FFFCE1] py-1">
                  <button onClick={toggleConfirmPassword}>
                    {conpasswordType === "password" ? (
                     // <img src={eyeopen} alt="new" /> 
                     <BsEye/>
                     ) : (
                       // <img src={eyeclose} alt="new" />
                       <BsEyeSlash/>
                    )}
                  </button>
                </div>
              </div>
              {passwordError && (
                <p className="text-red-500  text-center">
                  {passwordError}
                </p>
              )}
              {error && (
                <p className="text-red-500  text-center">{error}</p>
              )}
              <div className="flex justify-end">
                <button
                  onSubmit={handleSubmit}
                  type="submit"
                  className="bg-[#FCDD00] text-[#1A1A1A]  px-5 py-2 rounded-lg font-Poppins text-lg flex items-center justify-center gap-1 cursor-pointer hover:scale-105 outline-none"
                >
                  Update Password
                </button>
              </div>
            </div>
          </form>
 
        </div>
      </div>
    )

}
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import DoctorLayout from "../../component/layout/DoctorLayout/DoctorLayout";
// import action file 
import {
  GetDocEmpList,
  GetEmpFamilyDetail,
  GetEmpReportDetail,
} from "../../Redux/Action/DoctorAction";
//import icon image
import healthreport from "../../images/Doctor/healthreport.svg";
import { RiSearch2Line } from "react-icons/ri";
//import modal
import {
  FamilyDetailModal,
  ReportModal,
} from "../../component/Modal/DoctorModal/ReportModal";
import { Menu, Transition } from "@headlessui/react";
import ReactPaginate from "react-paginate";
import { ToastContainer } from "react-toastify";
import LimitArrow from "../../images/LimitArrow.svg"

const EmployeeList = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();

  const [selectpage, setselectpage] = useState("");
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openFamilyDetailModal, setOpenFamilyDetailModal] = useState(false);

  const itemsPerPageOptions = [
    { value: 10, label: 10, id: 1 },
    { value: 25, label: 25, id: 2 },
    { value: 50, label: 50, id: 3 },
  ];
  //get employe list 
  const EmpList = useSelector((state) => state?.DoctorData?.docemplist?.data);

  useEffect(() => {
    searchParams.delete("search");
    setSearchParams(searchParams);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    dispatch(GetDocEmpList({ ...object }));
    
  }, []);

  const [Itemperpage, setItemperpage] = useState("");
  const [page, setpage] = useState("");
  const pageCount = Math.ceil(Number(EmpList?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [EmpList]);

  useEffect(() => {
    setpage(itemsPerPageOptions[0]?.value);
  }, []);

  // for select page data
  const onselectedpage = (item) => {
    setpage(item.value);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      setpage(item.value);
      setselectpage(0)
      history({
        pathname: "/doctor/employee-list",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value, page:1 }).toString(),
      });
    } else {
      history({
        pathname: "/doctor/employee-list",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetDocEmpList({ ...object, limit: item.value,page:1 }));
    
  };

  // for pagination
  const handleFirstPage = () => {
    setselectpage(0);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/doctor/employee-list",
        search: "?" + new URLSearchParams({ ...object, page: 1 }).toString(),
      });
    } else {
      history({
        pathname: "/doctor/employee-list",
        search: `?page=1`,
      });
    }
    dispatch(GetDocEmpList({ ...object, page: 1 }));
  };
  const handleLastPage = () => {
    const lastPage = pageCount - 1;
    setselectpage(lastPage);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/doctor/employee-list",
        search:
          "?" +
          new URLSearchParams({ ...object, page: lastPage + 1 }).toString(),
      });
    } else {
      history({
        pathname: "/doctor/employee-list",
        search: `?page=${lastPage + 1}`,
      });
    }
    dispatch(GetDocEmpList({ ...object, page: lastPage + 1 }));
  };
  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/doctor/employee-list",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/doctor/employee-list",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetDocEmpList({ ...object, page: selected.selected + 1 }));
  };
  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if (newlimit?.length > 0) {
      setpage(newlimit);
    }
  }, []);
  useEffect(() => {
    const newpage = searchParams.get("page");
    if (newpage?.length > 0 && newpage !== null) {
      setselectpage(newpage - 1);
    }
  }, []);
  // for seacrch
  const [Search, setSearch] = useState("");
  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      delete(object.page);
      history({
        pathname: "/doctor/employee-list",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/doctor/employee-list",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetDocEmpList({ ...object, search: Search }));
    setselectpage(object.page = 0);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");
    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
      const sds = location.search.slice(1).split("&");
      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
      }
      delete(object.search)
    delete(object.page)
    history({
      pathname: "/doctor/employee-list",
      search:
        "?" +
        new URLSearchParams({
          ...object
        }).toString(),
    });
      dispatch(GetDocEmpList({ ...object }));
      setselectpage(object.page = 0);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  //for report modal
  const handleReport = (_id) => {
    dispatch(GetEmpReportDetail(_id));
    setOpenReportModal(true);
  };
  //for family detail modal
  const handleFamily = (_id) => {
    dispatch(GetEmpFamilyDetail(_id));
    setOpenFamilyDetailModal(true);
  };
   //for pagination
  const reactPaginate = {
    previousLabel: "<",
    nextLabel: ">",
    breakLabel: "",
    pageCount: Itemperpage,
    onPageChange: handlePageChange,
    containerClassName: "pagination",
    previousLinkClassName: "pagination__link",
    nextLinkClassName: "pagination__link",
    disabledClassName: "pagination__link--disabled",
    activeClassName: "pagination__link--active",
    forcePage: selectpage,
  };
  return (
    <>
      {/* report modal  */}
      {openReportModal && <ReportModal {...{ setOpenReportModal }} />}
      {/* family detail modal  */}
      {openFamilyDetailModal && (
        <FamilyDetailModal {...{ setOpenFamilyDetailModal }} />
      )}
      <DoctorLayout>
        <div className="flex flex-col h-full px-5 py-[20px] xl:py-[30px] 2xl:justify-between">
          {/* seacrch bar  */}
          <div className="bg-white p-4 mb-5 rounded-lg  w-full">
            <div className=" flex justify-between items-center">
              <div className="relative">
                <div className="absolute top-[8px] left-[20px] text-gray-400 text-2xl">
                  <RiSearch2Line className="w-6 h-6" />
                </div>
                <form
                  onSubmit={(e) => {
                    e?.preventDefault();
                    if (Search.trim() !== "") {
                      submitsearch(e);
                    }
                  }}
                >
                  <input
                    className="h-full bg-[#FFFCE1] border rounded-[8px] py-2 pl-[50px] w-[250px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium outline-[#FCDD00]"
                    placeholder="Search By Employee Name"
                    value={Search}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setSearch(inputValue);
                      if (inputValue.trim() === "") {
                        removeQueryParams();
                      }
                    }}
                  ></input>
                </form>
              </div>
            </div>
          </div>
          {/* table  */}
          <div className="w-full h-[520px] xl:h-[610px] overflow-auto scrollbar-thumb-[#FCDD00] scrollbar-thin scrollbar-track-gray-100">
            {EmpList?.employeeData?.length === 0 ? (
              <div className="flex justify-center items-center h-full">
                <p className="font-Roboto text-xl ">
                  No Employee Details Available.
                </p>
              </div>
            ) : (
              <table className="corporate-table w-[100%]  text-left font-Poppins">
                <thead>
                  <tr>
                  <th><div className="pl-[40px] py-3 xl:py-5 min-w-[320px]">Corporate Name</div></th>
                    <th><div className="min-w-[320px]">Employee Name</div></th>
                    <th><div className="min-w-[300px]">Email Id</div></th>
                    <th><div className="min-w-[160px]">Contact No.</div></th>
                    <th><div className="min-w-[130px]">Report</div></th>
                    <th><div className="min-w-[130px]">Family Details</div></th>
                    <th><div className="min-w-[150px]">Consultation</div></th>
                  </tr>
                </thead>
                <tbody>
                  {EmpList?.employeeData?.map((emp, index) => (
                    <tr key={index}>
                      {/* corporate name  */}
                      <td className="pl-[40px] py-2 xl:py-4 h-[46px]">
                        {emp?.corporateName && emp.corporateName[0] ? (
                          emp.corporateName[0].length > 30 ? (
                            <div className="tooltip">
                              <span>
                                {emp.corporateName[0].substring(0, 30)}...
                              </span>
                              <span className="tooltiptext">
                                {emp.corporateName[0]}
                              </span>
                            </div>
                          ) : (
                            <span>{emp.corporateName[0]}</span>
                          )
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                      {/* employee name  */}
                      {emp?.name?.length > 30 ? (
                        <td>
                          <div className="tooltip capitalize">
                            {`${emp?.name?.substring(0, 30)}...`}
                            <span className="tooltiptext">{emp.name}</span>
                          </div>
                        </td>
                      ) : (
                        <td className="capitalize">
                          <div>{emp.name}</div>
                        </td>
                      )}
                      {/* email  */}
                      {emp?.email?.length > 35 ? (
                        <td>
                          <div className="tooltip">
                            {`${emp?.email?.substring(0, 35)}...`}
                            <span className="tooltiptext">{emp.email}</span>
                          </div>
                        </td>
                      ) : (
                        <td>
                          <div>{emp.email}</div>
                        </td>
                      )}
                      {/* contact no  */}
                      <td><div>{emp.contact}</div></td>
                      {/* report  */}
                      <td>
                        <div className="pl-5">
                          <button onClick={() => handleReport(emp?._id)}>
                            <img src={healthreport} />
                          </button>
                        </div>
                      </td>
                      {/* family detail  */}
                      <td>
                        <div className="pl-5">
                          <button
                            onClick={() => handleFamily(emp?._id)}
                            className="bg-[#FCDD00] text-[14px] text-[#1A1A1A] rounded-lg px-5 py-1"
                          >
                            View
                          </button>
                        </div>
                      </td>
                      {/* consultation  */}
                      <td>
                        <div className="pl-4">
                          <a href="#"
                            className="bg-[#FCDD00] text-[14px] text-[#1A1A1A] rounded-lg px-5 py-1" >
                            Book
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <ToastContainer />
          </div>
          {/* footer  */}
          <div className="footer-content mt-5">
            <div className="md:flex justify-between items-center">
              {/* limit  */}
              <div className="flex gap-2 items-center">
                <p className="capitalize">View</p>
                <div className="">
                  <Menu as="div" className="relative text-left">
                    <div>
                      <Menu.Button className="flex px-[10px] ml-[24px] min-h-[37px] bg-white border-2 border-[#F2F2F2] min-w-[60px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                        <p className="text-[#47505F] font-medium font-[poppins]">
                          {page}
                        </p>
                        <img src={LimitArrow} alt="limit arrow" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[70%] rounded-[12px] bg-white shadoe-sm focus:outline-none border  mb-2">
                        {itemsPerPageOptions?.map((e) => (
                          <div key={e?.id}>
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-[#FFFCE1] text-[#1A1A1A]" : "",
                                    "block px-2 py-2 text-sm cursor-pointer"
                                  )}
                                  onClick={() => onselectedpage(e)}
                                >
                                  {e?.value}
                                </p>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              {/* pagination  */}
              <div className="flex items-center mt-3 md:mt-0">
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleFirstPage}
                >
                  &lt;&lt;
                </button>
                <div className="hidden md:block">
                <ReactPaginate {...reactPaginate}
                 marginPagesDisplayed={2}
                 pageRangeDisplayed={3}
                 />
                </div>
                <div className="md:hidden">
                <ReactPaginate {...reactPaginate}
                 marginPagesDisplayed={0}
                 pageRangeDisplayed={0}
                 />
                </div>
                <button
                  className="cursor-pointer text-[#000] text-base border border-[#D1D5DB] px-[15px] py-[4.5px] md:py-[5.5px]"
                  onClick={handleLastPage}
                >
                  &gt;&gt;
                </button>
              </div>
            </div>
          </div>
        </div>
      </DoctorLayout>
    </>
  );
};

export default EmployeeList;